import VuetableFieldHandle from 'vuetable-2/src/components/VuetableFieldHandle.vue'

export default [
  {
    name: VuetableFieldHandle
  },
  {
    name:'checked',
    title: ''
  },
  {
    name: 'readableId',
    title: 'Id',
    sortField: 'id'
  },
  'day',
  {
    name: 'reservationStatus',
    title: 'Res. Status'
  },
  {
    name: 'tripStatus',
    title: 'Trip Status'
  },
  {
    name: 'vehicleType',
    title: 'Vehicle type'
  },
  {
    name: 'passenger',
    title: 'Passengers'
  },
  {
    name: 'locations',
    title: 'PU / ST / DO'
  },
  {
    name: 'driver',
    title: 'Driver'
  },
  {
    name: 'price',
    title: 'Price'
  },
  {
    name: 'miles',
    title: 'Miles',
    formatter: (value) => {
      let str = '';
      if(value) {
        str = value.split(' mi')[0] ;
      } else {
        str = '-'
      }
      return str
    }
  },
  'extras',
  //'actions'
]