<template>
  <div>
    <md-toolbar 
      v-show="!alternateToolBar.active" 
      md-elevation="0" 
      ref="masterToolbar" 
      class="master-toolbar" 
      :class="{
        'expanded': !mainToolbarContracted,
        'paginator-present': reservations.mdData.length > 0,
        }">
        <div class="md-layout md-gutter md-alignment-center-left">
          
          
          <div v-show="!filters.searchById" class="md-layout-item md-xsmall-size-85" >
            <md-field>
              <label for="preset">Preset</label>
              <md-select v-model="filters.preset" name="preset" id="preset" :md-dense="dialogWidth < 992 ? true : false">
                <md-option :disabled="true" value="custom">Custom</md-option>
                <md-option value="today">Today</md-option>
                <md-option value="yesterday">Yesterday</md-option>
                <md-option value="tomorrow">Tomorrow</md-option>
                <md-option value="this month">This month</md-option>
                <md-option value="previous month">Previous month</md-option>
                <md-option value="next month">Next month</md-option>
                <md-option value="this week">This week</md-option>
                <md-option value="last week">Last week</md-option>
                <md-option value="next week">Next week</md-option>
                
              </md-select>
            </md-field>
            
          </div>
          <div class="md-layout-item md-xsmall-size-15" v-show="!filters.searchById && (dialogWidth < 992)">

            <md-button class="md-icon-button" @click="mainToolbarContracted = !mainToolbarContracted" v-if="dialogWidth < 992">
                <md-icon v-if="mainToolbarContracted">expand_more</md-icon>
                <md-icon v-if="!mainToolbarContracted">expand_less</md-icon>
            </md-button>
          </div>
          <div v-show="!filters.searchById && (!mainToolbarContracted || dialogWidth >= 992)" class="md-layout-item md-xsmall-size-50">  
            <VueCtkDateTimePicker
             
              v-model="fromDateString" 
              :format="'YYYY-MM-DD'"
              :formatted="'llll (HH:mm [HS])'"
              :output-format="'x'"
              :only-date="true"
              :label="'From'"
              :auto-close="true"
              :input="fromPickerInput()"
              :dark="true" 
             
              
  
             />
          </div>
          <div v-show="!filters.searchById && (!mainToolbarContracted || dialogWidth >= 992)" class="md-layout-item md-xsmall-size-50">
            <VueCtkDateTimePicker
            
               v-model="toDateString" 
               :format="'YYYY-MM-DD'"
               :formatted="'llll (HH:mm [HS])'"
               :output-format="'x'"
               :only-date="true"
               :label="'To'"
               :auto-close="true"
               :input="toPickerInput()"
               :dark="true" 
   
              />
          </div>
          <div v-show="!filters.searchById && (!mainToolbarContracted || dialogWidth >= 992)" class="md-layout-item md-xsmall-size-100">
            <md-field>
                <label for="">Company profile</label>
                  <md-select v-model="filters.companyProfile" name="company_profile" id="company-profile" placeholder="Company" :md-dense="dialogWidth < 992 ? true : false">
                      <md-option :value="0">Show All</md-option>
                      <md-option v-for="companyProfile in companyProfiles"  :value="companyProfile.id" v-bind:key="companyProfile.id">{{companyProfile.name}}</md-option>
                  </md-select>
                
            </md-field>
          </div>
          <div v-show="!filters.searchById && (!mainToolbarContracted || dialogWidth >= 992)" class="md-layout-item md-xsmall-size-50">
            <md-field md-inline>
                <md-icon>search</md-icon>
                <label>Search</label>
                <md-input v-model="filters.search"  :disabled="reservations.mdData.length == 0"></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-xsmall-size-50" v-show="!filters.searchById && (!mainToolbarContracted || dialogWidth >= 992)">
            <md-field>
              <label for="">Filter type</label>
                <md-select v-model="filters.searchById" placeholder="Filter type" :md-dense="dialogWidth < 992 ? true : false">
                    <md-option :value="false">Configurable</md-option>
                    <md-option :value="true">Filter by ID</md-option>
                </md-select>
              
            </md-field>
          </div>


          <div class="md-layout-item md-xsmall-size-85" v-show="filters.searchById">
            <md-field>
              <label for="">Filter type</label>
                <md-select v-model="filters.searchById" placeholder="Filter type" :md-dense="dialogWidth < 992 ? true : false">
                    <md-option :value="false">Configurable</md-option>
                    <md-option :value="true">Filter by ID</md-option>
                </md-select>
              
            </md-field>
          </div>
          <div class="md-layout-item md-xsmall-size-15" v-show="filters.searchById && (dialogWidth < 992)">

            <md-button class="md-icon-button" @click="mainToolbarContracted = !mainToolbarContracted" v-if="dialogWidth < 992">
                <md-icon v-if="mainToolbarContracted">expand_more</md-icon>
                <md-icon v-if="!mainToolbarContracted">expand_less</md-icon>
            </md-button>
          </div>

          <div v-show="filters.searchById && (!mainToolbarContracted || dialogWidth >= 992)" class="md-layout-item md-xsmall-size-50" >
            <md-field md-inline>
              <md-icon>search</md-icon>
              <label>Type ID</label>
              <md-input type="number" v-model="filters.goToID" ></md-input>
            </md-field>
          </div>
          <div v-show="filters.searchById && (!mainToolbarContracted || dialogWidth >= 992)" class="md-layout-item md-xsmall-size-50" >
            <md-button class="md-raised full-width-button md-primary" @click="fireQuery(null)"><md-icon class="mr-2">filter_alt</md-icon>Search res ID</md-button>
          </div>
          
          
          
          <div  class="md-layout-item md-xsmall-size-100" v-show="!mainToolbarContracted || dialogWidth >= 992">
            <md-button class="md-primary md-raised full-width-button" @click="newReservation">New reservation</md-button>
          </div>
        
          
        </div>
    </md-toolbar>
    <md-toolbar v-show="alternateToolBar.active">
        <div class="md-toolbar-section-start">
          <CRow>
            <CCol sm="12" md="12" lg="12" xl="12">
              <span class="md-title ml-0">Bulk actions</span>
            </CCol>
            <CCol sm="12" md="12" lg="12" xl="12">
              <div class="md-body">
                <b class="mr-1">{{alternateToolBar.count}} </b> {{alternateToolBar.count == 1 ? 'item' : 'items'}} selected (
                <span v-for="(selelectedItem, index) in alternateToolBar.items" v-bind:key="'selelectedItem-' + selelectedItem.id">
                    {{ alternateToolBar.items.length - 1 == index ? selelectedItem.readableId : alternateToolBar.count == 1 ? selelectedItem.readableId : selelectedItem.readableId + ',' }}  
                </span>
                )
              </div>
            </CCol>
          
          </CRow>
          
        </div>

        <div class="md-toolbar-section-end">
          <md-button class="md-icon-button" @click="doBulk('delete')">
            <md-icon>delete</md-icon>
          </md-button>
        </div>
    </md-toolbar> 
    <md-progress-bar md-mode="query" v-if="isBusy"></md-progress-bar>
    <div class="table-master-wrapper">
      <transition name="fixedcols" >
        <div class="fixed-table-columns" v-if="!isBusy && tableFixedColumns.data && tableFixedColumns.data.length > 0 && tableFixedColumns.show && dialogWidth >= 992" style="padding-top:37px">
            <div class="fixed-table-header" style="height:36px;">
              <div class="first-col" :style="{width: tableFixedColumns.data[0].columns.first + 'px'}">ID</div>
              <div class="second-col" :style="{width: tableFixedColumns.data[0].columns.second + 'px'}">Day</div>
            </div>
            <div class="fixed-columns-content" :style="{height: tableContentHeight + 'px'}" ref="fixedColumnsContent" :class ="{'shadowed': fixedColShadowed}">
              <div v-for="(row,index) in tableFixedColumns.data" v-bind:key="index + '-tableFixedColumnsData' + row.columns.id" >
                  <div v-if="row.columns.id" :style="{ height : row.height + 'px'}">
                    <div class="res-col first" :style="{width: row.columns.first + 'px',height : row.height + 'px'}"><a @click="editOrView( {id: row.columns.realid, readableId: row.columns.id})">{{ row.columns.id }}</a></div>
                    <div class="res-col second" :style="{width: row.columns.second + 'px',height : row.height + 'px'}"> 
                     
                        {{ parseInt(row.columns.date) | moment('timezone', row.columns.timezone , 'MMMM Do YYYY') }} <br>
                         <b>{{ parseInt(row.columns.date) | moment('timezone', row.columns.timezone , 'hh:mm A (HH:mm [HS])') }}</b>
                        
                  
                    </div>
                  </div>
                  <div v-else :style="{ height : row.height + 'px',  lineHeight  : row.height + 'px'}">
                    <div class="date-row">{{row.columns.date}}</div>
                  </div>
              </div>
            </div>
        </div>
      </transition>
      <md-table  class="up-table" v-model="filteredReservationsList"   md-sort="day" md-sort-order="asc" :class="'up-' + dialogLeft" md-fixed-header v-if="!isBusy && filteredReservationsList.length > 0" ref="masterTable" >
        <template v-for="(item, index) in filteredReservationsList" >
          <!--md-table-row v-bind:key="'a' + item.id" v-if="index === 0" class="blue-back">
            <md-table-cell colspan="15" class="date-bar">        
                {{ parseInt(item.day) | moment("dddd, MMMM Do YYYY") }}
            </md-table-cell>
          </md-table-row-->
          <md-table-row v-bind:key="'b' + item.id" v-if="index > 0 && isDiffDay(parseInt(item.day),parseInt(filteredReservationsList[index - 1].day))" class="blue-back" >
            <md-table-cell colspan="15" class="date-bar">        
                {{ parseInt(item.day) | moment("dddd, MMMM Do YYYY") }}
            </md-table-cell>
          </md-table-row>
          <template>
            <md-table-row  v-bind:key="'reservation-' + item.id + '-' + index" :data-datetime="item.day" :data-timezone="item.companyProfile.timeZone" :data-realid="item.id">
              
              <md-table-cell md-label="" class="compress-padding">
                <md-checkbox v-model="item.checked" @change="checkboxSelected(item)"></md-checkbox>
              </md-table-cell> 
              <md-table-cell md-label="ID" class="compress-padding"><a @click="editOrView(item)">{{ item.readableId }}</a></md-table-cell>
              <md-table-cell md-label="Day" md-sort-by="day" class="datetime-column">
                  <!-- {{ item.day | moment("dddd") }}<br> -->
                  <div class="md-layout">
                    <div class="md-layout-item">
                      <div class="no-wrap">
                        <span class="time-info">
                        {{ parseInt(item.day) | moment('timezone', item.companyProfile.timeZone , 'MMMM Do YYYY') }} <br>
                         <b>{{ parseInt(item.day) | moment('timezone', item.companyProfile.timeZone , 'hh:mm A (HH:mm [HS])') }}</b>
                         </span>
                     
                      </div>
                      <div class="no-wrap mt-2">
                       <span class="up-chip  up-text-confirmed mb-1 mr-2">Time Zone {{ item.companyProfile.timeZone }}</span>
                       <span class="up-chip  up-text-secondary mb-1">UTC {{ parseInt(item.day) | moment('timezone', item.companyProfile.timeZone , 'Z') }}</span>
                      </div>
                
                       
                      <div class="mt-2" v-html="checkTimeToGo(parseInt(item.day))"></div>
                      
                    </div>
                    <!--div class="md-layout-item">
                      <md-button @click="openModal('daytime',item)" class="md-icon-button md-mini up-edition">
                        <md-icon>edit</md-icon>
                      </md-button>
                    </div-->
                  </div>
                  
                  
              </md-table-cell>
              <md-table-cell md-label="Res. status" v-if="user.data.activeRole === 'company-admin'">
                
                  <md-field :class="item.reservationStatus" class="select-field">
                    <md-select v-model="item.reservationStatus" @md-selected="updateReservation(item,index,'resStatus')" >
                        <md-option v-for="reservationStatus in reservationStatuses" :value="reservationStatus.id" v-bind:key="reservationStatus.id">{{reservationStatus.name}}</md-option>
                    </md-select>
                  </md-field>
                  <md-progress-bar md-mode="indeterminate" class="mt-1" v-show="actualSaving.busy == true && actualSaving.index == index && actualSaving.col == 'resStatus'"></md-progress-bar>
              </md-table-cell>
              <md-table-cell md-label="Trip status">
                  <md-field :class="item.tripStatus" class="select-field">
                    <md-select v-model="item.tripStatus" @md-selected="updateReservation(item,index,'tripStatus')">
                        <md-option v-for="tripStatus in tripStatuses" :value="tripStatus.id" v-bind:key="tripStatus.id">{{tripStatus.name}}</md-option>
                    </md-select>
                  </md-field>
                  <md-progress-bar md-mode="indeterminate" class="mt-1" v-show="actualSaving.busy == true && actualSaving.index == index && actualSaving.col == 'tripStatus'"></md-progress-bar>
              </md-table-cell>
              <md-table-cell md-label="Assignment">
                <div
                  class="md-layout md-alignment-center-left" 
                  v-if="user.data.activeRole === 'company-admin' || user.data.activeRole === 'provider' "
                  >
                    <div v-if="item.offersBusy">
                      <md-progress-spinner :md-diameter="30" :md-stroke="3" md-mode="indeterminate" style="position:absolute"></md-progress-spinner>
                    </div>
                    <div v-else-if="!item.offersBusy">
                      <div class="md-layout-item flex-1">
                        <md-chip class="mb-1 mt-2 full-width-button" :class="[ statusClass(statuses[item.driverStatus])  ]">Status: {{ statuses[item.driverStatus] }}</md-chip>
                        <md-chip class="full-width-button" v-if="item.acceptedOffers > 0"> Acepted: {{ item.acceptedOffers }}</md-chip>
                        <md-chip class="full-width-button" v-if="item.assignedDriver"> Driver: {{ item.assignedDriver.name }} {{ item.assignedDriver.lastname }} / {{ item.assignedDriver.phone }} </md-chip>
                       
                       <div class="md-layout md-alignment-center-left" >
                        <div class="md-layout-item">

                          <md-button class="ml-0 margin-right-5px md-raised md-secondary md-dense" @click="startDriverAssignment(item,index)" v-if="!item.directAssignment">
                          {{  statuses[item.driverStatus] === 'Offered' ? 'Manage offers' : ''}} 
                          {{  statuses[item.driverStatus] === 'Unassigned' ? 'Offer' : ''}} 
                          {{  statuses[item.driverStatus] === 'Assigned' ? 'Manage assignment' : ''}} 
                          {{  statuses[item.driverStatus] === 'Pre assigned' ? 'Manage assignment' : ''}} 
                          {{  statuses[item.driverStatus] === 'On hold' ? 'Manage assignment' : ''}} 
                          </md-button>
                        </div>
                        <div class="md-layout-item">
                          
                          <md-button class="md-raised" @click="startDirectAssignment(item,index)">
                             {{ item.directAssignment ? 'Manage direct assignment' :  'Assign'}}
                          </md-button>
                        </div>
                       </div>
                       

                        
                      </div>
                      

                    </div>
                </div>


                <div
                  class="md-layout md-alignment-center-left"
                  v-if="user.data.activeRole === 'driver' && user.data.activeSubrole === 'owner-operator'"
                  >
                  <div class="md-layout-item flex-1">
                    <md-chip class="mb-2 mt-2 full-width-button" :class="[ statusClass(statuses[item.driverStatus])  ]">Status: {{ statuses[item.driverStatus] }}</md-chip>
                    <md-button class="md-raised full-width-button" v-if="item.autoassignable">Self assign job</md-button>

                  </div>
                </div>
                  
              </md-table-cell>
              <md-table-cell md-label="PU / ST / DO">
                <div class="md-layout md-alignment-center-left">
                  <div class="md-layout-item flex-1">
                    <div class="location-text no-wrap mb-2 text-magic-ellipsis max-width-200">
                      <b>PU:</b> {{item.pu.formatted_address}}
                      <md-tooltip md-direction="top" md-delay="500"><b>PU:</b> {{item.pu.formatted_address}}</md-tooltip>
                    </div>
                    <div class="location-text no-wrap mb-2 text-magic-ellipsis max-width-200" v-if="item.waypoints.length > 0">
                        <div v-for="(waypoint,index) in item.waypoints" class="location-text no-wrap" v-bind:key="'waypoint' + index">
                          <b>ST#{{index +1 }}:</b> {{waypoint.formatted_address}}
                          <md-tooltip md-direction="top" md-delay="500"><b>ST#{{index +1 }}:</b> {{waypoint.formatted_address}}</md-tooltip>
                        </div>
                    </div>
                    <div class="location-text no-wrap text-magic-ellipsis max-width-200">
                      <b>DO:</b> {{item.do.formatted_address}}
                       <md-tooltip md-direction="top" md-delay="500"><b>DO:</b> {{item.do.formatted_address}}</md-tooltip>
                    </div>
                  </div>
                  <div class="md-layout-item flex-none pl-2">
                    <md-button @click="openMapDialog(item)" class="min-width-55">
                      <md-icon>route</md-icon>
                       <md-tooltip md-direction="top" md-delay="1000">View map</md-tooltip>
                    </md-button>
                  </div>
                </div>
                 
              </md-table-cell>
              <md-table-cell md-label="Flight schedule" v-if="user.data.activeRole === 'company-admin'">
                 <!-- TODO: Flight schedule renew -->
                  <div v-if="item.flightPack && item.flightPack.schedule">
                    <span class="md-body-1 up-text-primary  no-wrap font-weight-bold cursor-pointer" @click="openFlightDialog(item)" >
                        <span v-if="item.flightPack.icao != null && item.flightPack.icao != ''">
                            {{ item.flightPack.icao }}
                        </span>
                        <span v-if="item.flightPack.icao != null && item.flightPack.icao != '' && item.flightPack.iata != null && item.flightPack.iata != ''">/</span>
                        <span v-if="item.flightPack.iata != null && item.flightPack.iata != ''">
                            {{ item.flightPack.iata }}
                        </span>

                        {{item.flightPack.number}}
                      
                        <md-icon class="mini-icon ml-2">
                          info
                        </md-icon>
                        <md-tooltip md-direction="top" md-delay="1000">View flight details</md-tooltip>
                    </span>
                    <!--md-button @click="openFlightDialog(item)" class="ml-3">
                      <md-tooltip md-direction="top">Detailed flight info</md-tooltip>
                      <md-icon @click="openFlightDialog(item)">info</md-icon>
                    </md-button-->
                  
                    <div class="md-layout md-alignment-center-center">
                      <div class="md-layout-item" >
                        <div v-if="getJobTypeName(item.jobType.jobType) == 'To Airport'">
                          <div class="md-caption">Scheduled departure</div>
                          <span class="md-body-2 text-magic-ellipsis"  v-if="item.flightPack.originAirport.iata">{{item.flightPack.schedule.origin.code_icao}}  / {{item.flightPack.originAirport.iata }}</span>

                          
                          <div class="no-wrap md-body-2">{{ item.flightPack.schedule.scheduled_out | moment('timezone', item.flightPack.originAirport.tz , 'hh:mm A YYYY-MM-DD') }}</div>
                          
                          
                        </div>
                    
                        <div v-if="getJobTypeName(item.jobType.jobType) == 'From airport'">
                          <div class="md-caption">Scheduled arrival</div>
                          <span class="md-body-2 text-magic-ellipsis" :class="{'up-line-through': item.flightPack.diverted  }"  v-if="item.flightPack.destinationAirport.iata">{{item.flightPack.schedule.destination.code_icao}} / {{item.flightPack.destinationAirport.iata }}</span>
                          <span class="md-body-2 text-magic-ellipsis ml-2"   v-if="item.flightPack.diverted && item.flightPack.divertedDestinationAirport.iata">{{item.flightPack.divertedLive.destination.code_icao}} / {{item.flightPack.divertedDestinationAirport.iata }}</span>
                          
                          <div class="no-wrap md-body-2" :class="{'up-line-through': item.flightPack.diverted  }">{{ item.flightPack.schedule.scheduled_in | moment('timezone', item.flightPack.destinationAirport.tz , 'hh:mm A YYYY-MM-DD') }}</div>
                          <div class="no-wrap md-body-2" v-if="item.flightPack.diverted" >{{ item.flightPack.divertedLive.estimated_on | moment('timezone', item.flightPack.divertedDestinationAirport.tz , 'hh:mm A YYYY-MM-DD') }}</div>
                          
                          
                          
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else>-</div>    
                  


                
              </md-table-cell>
              <md-table-cell md-label="Flight live status" v-if="user.data.activeRole === 'company-admin'">
                  <div v-if="item.flightPack && item.flightPack.schedule">
                    <div class="md-layout md-alignment-center-center">
                        <div class="md-layout-item" >
                          <div v-if="getJobTypeName(item.jobType.jobType) == 'To Airport'">
                            <div v-if="item.flightPack.live">
                              
                              <md-chip v-if="item.flightPack.live.cancelled" class="mini uplimo-red">Cancelled</md-chip> 
                              <md-chip v-else-if="item.flightPack.live.actual_off == null" class="mini uplimo-orange">On land</md-chip> 
                              <md-chip v-else class="mini uplimo-green">Departed</md-chip> 
                          
                              
                              <div v-if="item.flightPack.live.actual_off == null" class="md-subheading up-text-caution">Gate departure {{ item.flightPack.live.estimated_out | moment('timezone', item.flightPack.originAirport.tz , 'hh:mm A YYYY-MM-DD') }}</div>
                              <div v-else-if="item.flightPack.live.actual_off" class="md-subheading  no-wrap up-text-confirmed">{{ item.flightPack.live.actual_off | moment('timezone', item.flightPack.originAirport.tz , 'hh:mm A YYYY-MM-DD') }}</div>
                              <div class="md-caption no-wrap up-bg-dark p-1 pr-2 pl-2 br-1 rounded-pill mt-2 text-white">Last updated at {{ item.flightPack.lastLiveUpdate | moment('timezone', item.companyProfile.timeZone , 'YYYY-MM-DD hh:mm A (HH:mm [HS])') }}</div>
                            </div>
                          </div>
                          <div v-if="getJobTypeName(item.jobType.jobType) == 'From airport'">
                            <div v-if="item.flightPack.live">
                                
                                <md-chip v-if="item.flightPack.live.cancelled" class="mini uplimo-red">Cancelled</md-chip> 
                                <md-chip v-else-if="item.flightPack.live.actual_on == null && item.flightPack.live.actual_off != null" class="mini uplimo-yellow">On air</md-chip> 
                                <md-chip v-else-if="item.flightPack.live.actual_on" class="mini uplimo-green">Landed</md-chip> 
                            
                                
                                <div v-if="!item.flightPack.live.actual_on" class="md-subheading up-text-caution">ETA {{ item.flightPack.live.estimated_on | moment('timezone', item.flightPack.destinationAirport.tz , 'hh:mm A YYYY-MM-DD') }}</div>
                                <div v-else-if="item.flightPack.live.actual_on" class="no-wrap md-subheading up-text-confirmed">{{ item.flightPack.live.actual_on | moment('timezone', item.flightPack.destinationAirport.tz , 'hh:mm A YYYY-MM-DD') }}</div>
                                <div class="md-caption no-wrap up-bg-dark pr-2 pl-2 p-1 br-1 rounded-pill mt-2 text-white">Last updated at {{ item.flightPack.lastLiveUpdate | moment('timezone', item.companyProfile.timeZone , 'YYYY-MM-DD hh:mm A (HH:mm [HS])') }}</div>
                            </div>
                            <div v-if="item.flightPack.divertedLive">
                                
                                <md-chip v-if="item.flightPack.diverted" class="mini uplimo-red">Diverted</md-chip> 
                                <md-chip v-if="item.flightPack.divertedLive.cancelled" class="mini uplimo-red">Cancelled</md-chip> 
                                <md-chip v-else-if="item.flightPack.divertedLive.actual_on == null && item.flightPack.divertedLive.actual_off != null" class="mini uplimo-yellow">On air</md-chip> 
                                <md-chip v-else-if="item.flightPack.divertedLive.actual_on" class="mini uplimo-green">Landed</md-chip> 
                            
                                
                                <div v-if="!item.flightPack.divertedLive.actual_on" class="md-subheading up-text-caution">ETA {{ item.flightPack.divertedLive.estimated_on | moment('timezone', item.flightPack.divertedDestinationAirport.tz , 'hh:mm A YYYY-MM-DD') }}</div>
                                <div v-else-if="item.flightPack.divertedLive.actual_on" class="no-wrap md-subheading up-text-confirmed">{{ item.flightPack.divertedLive.actual_on | moment('timezone', item.flightPack.divertedDestinationAirport.tz , 'hh:mm A YYYY-MM-DD') }}</div>
                                <div class="md-caption no-wrap up-bg-dark pr-2 pl-2 p-1 br-1 rounded-pill mt-2 text-white">Last updated at {{ item.flightPack.lastLiveUpdate | moment('timezone', item.companyProfile.timeZone , 'YYYY-MM-DD hh:mm A (HH:mm [HS])') }}</div>
                            </div>
                          </div>
                         </div>
                    </div>
                  </div>
                  <div v-else>-</div> 
                  <!--md-button @click="fireCheck">Fire check</md-button-->      
              </md-table-cell>
              <md-table-cell md-label="Passenger">
                <div v-if="item.passengers">
                    <div class="md-layout passenger-layout">
                        <div class="md-layout-item pr-4">
                          
                          <carousel :perPage="1" :navigationEnabled="true" :paginationEnabled="false" :autoplay="true" :loop="true" :adjustableHeight="false">
                            <slide v-for="(passenger,ind) in item.passengers"
                                v-bind:key="'pass-' + ind"
                                :data-index="ind"
                                :data-name="passenger.type + 'note'"
                                >
                              <div class="no-wrap  text-magic-ellipsis md-subheading">{{passenger.firstName}} {{passenger.lastName}}</div>
                              <p class="no-wrap  text-magic-ellipsis md-title">{{passenger.phone}}</p>
                              
                            </slide>
                          
                          </carousel>
                          
                        </div>
                        <div class="md-layout-item">
                          <div class="qty-container">
                            <md-icon>emoji_people</md-icon>
                            <span class="big-qty">{{item.passenger.qty}}</span>
                          </div>
                          <div class="qty-container">
                            <md-icon>luggage</md-icon>
                            <span class="big-qty">{{item.passenger.luggage}}</span>
                          </div>
                          
                        </div>
                        <div class="md-layout-item" v-show="false">
                          <md-button @click="openModal('passenger',item)" class="md-icon-button md-mini up-edition">
                            <md-icon>edit</md-icon>
                          </md-button>
                        </div>
                    </div>
                          
                </div>

              </md-table-cell>
              <md-table-cell md-label="Company" v-if="user.data.activeRole === 'driver'">
                <div class="md-layout md-alignment-center-left">
                  <div class="md-layout-item flex-1">
                    {{ item.companyProfile.name }} 
                    <ul class="pl-0">
                      <li v-for="phone in item.companyProfile.phones" v-bind:key="phone + item.companyProfile.id">
                          {{ phone }}
                      </li>
                    </ul>
                  </div>
                </div>
              </md-table-cell>
              <md-table-cell md-label="Extras">
                <div class="md-layout">
                  <div class="md-layout-item with-switch">
                    <md-switch v-model="item.meetAndGreet" @change="updateReservation(item,index,'extras')"></md-switch>
                    <md-icon v-bind:class="{ 'active-icon': item.meetAndGreet }">thumb_up</md-icon>
                      
                  </div>
                  <div class="md-layout-item with-switch">
                    <md-switch v-model="item.childSeat" @change="updateReservation(item,index,'extras')"></md-switch>
                    <md-icon v-bind:class="{ 'active-icon': item.childSeat }">child_friendly</md-icon>
                      
                  </div>
                  <div class="md-layout-item with-switch">
                    <md-switch v-model="item.accessible" @change="updateReservation(item,index,'extras')"></md-switch>
                    <md-icon v-bind:class="{ 'active-icon': item.accessible }">accessible</md-icon>
                      
                  </div>
                </div>
                <md-progress-bar md-mode="indeterminate" class="mt-2" v-show="actualSaving.busy == true && actualSaving.index == index && actualSaving.col == 'extras'"></md-progress-bar>
      
              </md-table-cell>
              
              <md-table-cell md-label="Vehicle type" v-if="user.data.activeRole === 'company-admin'">
                  <md-field :class="item.vehicleType" class="select-field">
                    <md-select v-model="item.vehicleType.id"  disabled>
                        <md-option v-for="vehicleType in vehicleTypes" :value="vehicleType.id" v-bind:key="vehicleType.id">{{vehicleType.type}}</md-option>
                    </md-select>
                  </md-field>
              </md-table-cell>
              <md-table-cell md-label="Price" class="price" :class="paymentStatus(item.paymentStatus)" v-if="user.data.activeRole === 'company-admin'">
                  {{ item.generatedPrice ? roundNumber(item.generatedPrice) : '' }}
              </md-table-cell>
              <md-table-cell class="miles" md-label="Miles">
                  {{item.miles}}
              </md-table-cell>
              <md-table-cell md-label="Notes" class="note-cell">
                <carousel :perPage="1" :navigationEnabled="true" :paginationEnabled="false" :autoplay="true" :loop="true" :adjustableHeight="false">
                  <slide v-for="(note,ind) in item.notes"
                      v-bind:key="'note-' + ind"
                      :data-index="ind"
                      :data-name="note.type + 'note'"
                      >
                    <div class="note-label">{{note.label}}</div>
                    <p class="no-wrap note-text max-width-100 text-magic-ellipsis">{{note.text}}</p>
                    
                  </slide>
                
                </carousel>
              </md-table-cell>
            </md-table-row>
          </template>
          
        </template>
        
        <md-table-pagination
          :md-page-size="rowsPerPage"
          :md-page-options="[1,2,3,5,10,15,20,25,30,50,100,200]"
          :md-update="updatePagination"
          :md-data.sync="reservations"
          :md-paginated-data.sync="filteredReservationsList" 
          v-show="reservations.mdData.length > 0"
          ref="paginator"
          />
      </md-table>
      <md-empty-state
           md-icon="manage_search"
           v-if="filteredReservationsList.length == 0 && !isBusy"
          md-label="No reservations found"
        
          :md-description="`No reservations found for this query`"
          >
          <md-button class="md-primary md-raised" @click="newReservation">Create New Reservation</md-button>
      </md-empty-state>  
    </div>
    
   
    <md-snackbar md-position="center" :md-duration="isInfinity ? Infinity : duration" :md-active.sync="showSnackbar" md-persistent>
      <span>{{snackMessage}}</span>
      <md-button class="md-primary" @click="showSnackbar = false">Ok</md-button>
    </md-snackbar>
    <md-dialog :md-active.sync="driverAssignment.auxModal" :style="dialogDimensions" :md-fullscreen="false" class="free-dialog scrollable-content-dialog">
      <md-dialog-title v-if="driverAssignment.actualRes">Reservation id {{driverAssignment.actualRes.readableId}}</md-dialog-title>
      <div class="modal-up-content" v-if="driverAssignment.actualRes">
        <div v-if="driverAssignment.actualRes.offers && driverAssignment.actualRes.offers.length > 0">
          <md-table>
            <md-table-row>
            
              <md-table-head>Email</md-table-head>
              <md-table-head>Code</md-table-head>
              
            </md-table-row>
              <md-table-row v-for="offer in driverAssignment.actualRes.offers" v-bind:key="offer.id">
              
              <md-table-cell>{{ offer.email}}</md-table-cell>
              <md-table-cell>{{ offer.code }}</md-table-cell>
            
            </md-table-row>
          </md-table>
          
          
        
        </div>
        <div v-if="statuses[driverAssignment.actualRes.driverStatus] === 'Unassigned'">
          <p>Click "Start Driver Assignment" to begin offering</p>
        </div>
      </div>
      <md-dialog-actions>
        <md-button class="md-primary" @click="driverAssignment.auxModal = false">Close</md-button>
      </md-dialog-actions>
    </md-dialog>
    
    <md-dialog :md-active.sync="flightDialog" :style="[dialogDimensions]" v-if="actualFlightPack" :md-fullscreen="false" class="free-dialog scrollable-content-dialog">
      <md-dialog-title>Reservation <span class="up-text-primary ">{{actualFlightPack.readableId}} </span>
        <div class="md-caption">Flight details ({{ getJobTypeName(actualFlightPack.jobType) }})</div>
      </md-dialog-title>
      <md-divider class=" mb-4" v-show="false">
      </md-divider>
      <!-- TODO: Dialog Flight schedule renew -->
      <div class="modal-up-content">
        <CRow  class="mb-2">
            <CCol sm="12">

                <div>Matched flight</div>
                <div class="md-title">
                    <span v-if="actualFlightPack.icao != null && actualFlightPack.icao != ''">
                        {{ actualFlightPack.icao }}
                    </span>
                    <span v-if="actualFlightPack.icao != null && actualFlightPack.icao != '' && actualFlightPack.iata != null && actualFlightPack.iata != ''">/</span>
                    <span v-if="actualFlightPack.iata != null && actualFlightPack.iata != ''">
                        {{ actualFlightPack.iata }}
                    </span>

                    {{actualFlightPack.number}}
                </div>
                
            </CCol>
        </CRow>
        <CRow v-if="actualFlightPack.schedule" class="mb-4">
            
            <CCol :sm="actualFlightPack.diverted ? 4 : 6">
                <md-icon class="mr-2">flight_takeoff</md-icon>
                <div class="md-subheading mb-1">Departure</div>
                <div class="md-title up-text-primary">{{actualFlightPack.schedule.origin.code_icao}} <span v-if="actualFlightPack.schedule.origin.code_iata">/ {{actualFlightPack.schedule.origin.code_iata }}</span></div>
                <div class="md-body-1 text-magic-ellipsis">{{actualFlightPack.originAirport.name }}</div>
                <div class="md-caption">{{actualFlightPack.originAirport.city }} / {{actualFlightPack.originAirport.country }}</div>
                <div v-if="actualFlightPack.live">

                  <md-divider class="mt-1 mb-2"></md-divider>
                  <span class="md-caption mr-2">Actual status</span>
                  <md-chip v-if="actualFlightPack.live.cancelled" class="mini uplimo-red">Cancelled</md-chip> 
                  <md-chip v-else-if="actualFlightPack.live.actual_off == null" class="mini uplimo-orange">On land</md-chip> 
                  <md-chip v-else class="mini uplimo-green">Departed</md-chip> 
              
                  
                  <div v-if="actualFlightPack.live.actual_off == null" class="md-subheading up-text-caution text-magic-ellipsis">Gate departure {{ actualFlightPack.live.actual_out | moment('timezone', actualFlightPack.originAirport.tz , 'hh:mm A YYYY-MM-DD') }}</div>
                  <div v-else-if="actualFlightPack.live.actual_off" class="md-subheading  no-wrap up-text-confirmed text-magic-ellipsis">{{ actualFlightPack.live.actual_off | moment('timezone', actualFlightPack.originAirport.tz , 'hh:mm A YYYY-MM-DD') }}</div>
                  <div class="md-caption no-wrap up-text-soft-black">Last updated at {{ actualFlightPack.lastLiveUpdate | moment('timezone', actualFlightPack.companyProfile.timeZone , 'YYYY-MM-DD hh:mm A (HH:mm [HS])') }}</div>
                </div>

                <div v-if="actualFlightPack.divertedLive">
                  <md-divider class="mt-1 mb-2"></md-divider>
                  <span class="md-caption mr-2">Actual status</span>
                  <md-chip v-if="actualFlightPack.divertedLive.cancelled" class="mini uplimo-red">Cancelled</md-chip> 
                  <md-chip v-else-if="actualFlightPack.divertedLive.actual_off == null" class="mini uplimo-orange">On land</md-chip> 
                  <md-chip v-else class="mini uplimo-green">Departed</md-chip> 
              
                  
                  <div v-if="actualFlightPack.divertedLive.actual_off == null" class="md-subheading up-text-caution text-magic-ellipsis">Gate departure {{ actualFlightPack.divertedLive.actual_out | moment('timezone', actualFlightPack.originAirport.tz , 'hh:mm A YYYY-MM-DD') }}</div>
                  <div v-else-if="actualFlightPack.divertedLive.actual_off" class="md-subheading  no-wrap up-text-confirmed text-magic-ellipsis">{{ actualFlightPack.divertedLive.actual_off | moment('timezone', actualFlightPack.originAirport.tz , 'hh:mm A YYYY-MM-DD') }}</div>
                  <div class="md-caption no-wrap up-text-soft-black">Last updated at {{ actualFlightPack.lastLiveUpdate | moment('timezone', actualFlightPack.companyProfile.timeZone , 'YYYY-MM-DD hh:mm A (HH:mm [HS])') }}</div>
                </div>
                
                <md-divider class="mb-2 mt-2"></md-divider>
                <span class="md-caption">Schedule</span>
                <div class="md-subheading">{{ actualFlightPack.schedule.scheduled_out | moment('timezone', actualFlightPack.originAirport.tz , 'YYYY-MM-DD') }}</div>
                <div class="md-subheading">{{ actualFlightPack.schedule.scheduled_out | moment('timezone', actualFlightPack.originAirport.tz , 'hh:mm A (HH:mm [HS])')  }}</div>
                <div class="md-caption">Timezone: {{actualFlightPack.originAirport.tz }}</div>
                <md-divider class="mb-2 mt-2"></md-divider>
                <CRow v-if="actualFlightPack.info" >
                    <CCol sm="12">
                        <div>Terminal: {{actualFlightPack.schedule.terminal_origin != '' ? actualFlightPack.schedule.terminal_origin : '-' }}</div>
                        <div>Gate:  {{actualFlightPack.schedule.gate_origin != '' ? actualFlightPack.schedule.gate_origin : '-' }}</div>
                    </CCol>
                </CRow>
                

            </CCol>
            <CCol :sm="actualFlightPack.diverted ? 4 : 6">
                <md-icon class="mr-2">flight_land</md-icon>
                <div class="md-subheading mb-1">{{ actualFlightPack.diverted ? 'Diverted arrival' : 'Arrival'}}</div>

                <div class="md-title up-text-primary" :class="{'up-line-through': actualFlightPack.diverted  }">{{actualFlightPack.schedule.destination.code_icao}} <span v-if="actualFlightPack.schedule.destination.code_iata">/ {{actualFlightPack.schedule.destination.code_iata }}</span></div>
                <div class="md-title up-text-primary" v-if="actualFlightPack.divertedDestinationAirport">{{actualFlightPack.divertedLive.destination.code_icao}} <span v-if="actualFlightPack.divertedDestinationAirport.iata">/ {{actualFlightPack.divertedDestinationAirport.iata }}</span></div>

                <div class="md-body-1 text-magic-ellipsis" :class="{'up-line-through': actualFlightPack.diverted  }">{{actualFlightPack.destinationAirport.name }}</div>
                <div class="md-body-1 text-magic-ellipsis" v-if="actualFlightPack.diverted">{{actualFlightPack.divertedDestinationAirport.name }}</div>

                <div class="md-caption" :class="{'up-line-through': actualFlightPack.diverted }" >{{actualFlightPack.destinationAirport.city }} / {{actualFlightPack.destinationAirport.country }}</div>
                <div class="md-caption"  v-if="actualFlightPack.diverted">{{actualFlightPack.divertedDestinationAirport.city }} / {{actualFlightPack.divertedDestinationAirport.country }}</div>
                
                 
                 <div v-if="actualFlightPack.live">
                  <md-divider class="mt-1 mb-2"></md-divider>
                
                  <span class="md-caption mr-2">
                    Actual status
                  </span>
                  
              
                  <md-chip v-if="actualFlightPack.live.cancelled" class="mini uplimo-red">Cancelled</md-chip> 
                  <md-chip v-else-if="actualFlightPack.live.actual_on == null && actualFlightPack.live.actual_off != null  " class="mini uplimo-yellow">On air</md-chip> 
                  <md-chip v-else-if="actualFlightPack.live.actual_on" class="mini uplimo-green">Landed</md-chip> 
            
                
                  <div v-if="!actualFlightPack.live.actual_on" class="md-subheading up-text-caution text-magic-ellipsis">ETA {{ actualFlightPack.live.estimated_on | moment('timezone', actualFlightPack.destinationAirport.tz , 'hh:mm A YYYY-MM-DD') }}</div>
                  <div v-else-if="actualFlightPack.live.actual_on" class="no-wrap md-subheading up-text-confirmed text-magic-ellipsis">{{ actualFlightPack.live.actual_on | moment('timezone', actualFlightPack.destinationAirport.tz , 'hh:mm A YYYY-MM-DD') }}</div>
                  <div class="md-caption no-wrap up-text-soft-black">Last updated at {{ actualFlightPack.lastLiveUpdate | moment('timezone', actualFlightPack.companyProfile.timeZone , 'YYYY-MM-DD hh:mm A (HH:mm [HS])') }}</div>
                 </div>
                 <div v-if="actualFlightPack.divertedLive">
                  <md-divider class="mt-1 mb-2"></md-divider>
                
                  <span class="md-caption mr-2">
                    Actual status
                  </span>
                  
              
                  <md-chip v-if="actualFlightPack.divertedLive.cancelled" class="mini uplimo-red">Cancelled</md-chip> 
                  <md-chip v-else-if="actualFlightPack.divertedLive.actual_on == null && actualFlightPack.divertedLive.actual_off != null" class="mini uplimo-yellow">On air</md-chip> 
                  <md-chip v-else-if="actualFlightPack.divertedLive.actual_on > 0" class="mini uplimo-green">Landed</md-chip> 
            
                
                  <div v-if="!actualFlightPack.divertedLive.actual_on" class="md-subheading up-text-caution text-magic-ellipsis">ETA {{ actualFlightPack.divertedLive.estimated_on | moment('timezone', actualFlightPack.divertedDestinationAirport.tz , 'hh:mm A YYYY-MM-DD') }}</div>
                  <div v-else-if="actualFlightPack.divertedLive.actual_on" class="no-wrap md-subheading up-text-confirmed text-magic-ellipsis">{{ actualFlightPack.divertedLive.actual_on | moment('timezone', actualFlightPack.divertedDestinationAirport.tz , 'hh:mm A YYYY-MM-DD') }}</div>
                  <div class="md-caption no-wrap up-text-soft-black">Last updated at {{ actualFlightPack.lastLiveUpdate | moment('timezone', actualFlightPack.companyProfile.timeZone , 'YYYY-MM-DD hh:mm A (HH:mm [HS])') }}</div>
                 </div>

                <md-divider class="mb-2 mt-2"></md-divider>
                <span class="md-caption">Schedule</span>
                <div class="md-subheading">{{ actualFlightPack.schedule.scheduled_in | moment('timezone', actualFlightPack.destinationAirport.tz , 'YYYY-MM-DD') }}</div>
                <div class="md-subheading">{{ actualFlightPack.schedule.scheduled_in | moment('timezone', actualFlightPack.destinationAirport.tz , 'hh:mm A (HH:mm [HS])') }}</div>
                <div class="md-caption">Timezone: {{actualFlightPack.destinationAirport.tz }}</div>
                <md-divider class="mb-2 mt-2"></md-divider>
                <CRow v-if="actualFlightPack.info" >
                    <CCol sm="12">
                        <div>Terminal: {{actualFlightPack.schedule.terminal_destination != '' ? actualFlightPack.schedule.terminal_destination : '-' }}</div>
                        <div>Gate:  {{actualFlightPack.schedule.gate_destination != '' ? actualFlightPack.schedule.gate_destination : '-' }}</div>
                        <div>Bag claim:  {{actualFlightPack.schedule.baggage_claim != '' ? actualFlightPack.schedule.baggage_claim : '-' }}</div>
                    </CCol>
                </CRow>
                
            </CCol>
            <CCol v-if="actualFlightPack.diverted" sm="4">
                <md-icon class="mr-2">flight_land</md-icon>
                <div class="md-subheading mb-1">{{ actualFlightPack.diverted ? 'Original arrival' : 'Arrival'}}</div>
                <div class="md-title up-text-primary" >{{actualFlightPack.schedule.destination.code_icao}} <span v-if="actualFlightPack.schedule.destination.code_iata">/ {{actualFlightPack.schedule.destination.code_iata }}</span></div>
                <div class="md-body-1 text-magic-ellipsis">{{actualFlightPack.destinationAirport.name }}</div>
                <div class="md-caption">{{actualFlightPack.destinationAirport.city }} / {{actualFlightPack.destinationAirport.country }}</div>
                
                 
                 <div v-if="actualFlightPack.divertedToScheduledDestinationLive">
                  <md-divider class="mt-1 mb-2"></md-divider>
                
                  <span class="md-caption mr-2">
                    Actual status
                  </span>
                  
              
                  <md-chip v-if="actualFlightPack.divertedToScheduledDestinationLive.cancelled" class="mini uplimo-red">Cancelled</md-chip> 
                  <md-chip v-else-if="actualFlightPack.divertedToScheduledDestinationLive.actual_on == null && actualFlightPack.divertedToScheduledDestinationLive.actual_off != null " class="mini uplimo-yellow">On air</md-chip> 
                  <md-chip v-else-if="actualFlightPack.divertedToScheduledDestinationLive.actual_on > 0" class="mini uplimo-green">Landed</md-chip> 
            
                
                  <div v-if="!actualFlightPack.divertedToScheduledDestinationLive.actual_on" class="md-subheading up-text-caution text-magic-ellipsis">ETA {{ actualFlightPack.divertedToScheduledDestinationLive.estimated_on | moment('timezone', actualFlightPack.destinationAirport.tz , 'hh:mm A YYYY-MM-DD') }}</div>
                  <div v-else-if="actualFlightPack.divertedToScheduledDestinationLive.actual_on > 0" class="no-wrap md-subheading up-text-confirmed text-magic-ellipsis">{{ actualFlightPack.divertedToScheduledDestinationLive.actual_on | moment('timezone', actualFlightPack.destinationAirport.tz , 'hh:mm A YYYY-MM-DD') }}</div>
                  <div class="md-caption no-wrap up-text-soft-black">Last updated at {{ actualFlightPack.lastLiveUpdate | moment('timezone', actualFlightPack.companyProfile.timeZone , 'YYYY-MM-DD hh:mm A (HH:mm [HS])') }}</div>
                 </div>
                

                
                
            </CCol>
        </CRow>
        
       
      </div>
      <md-dialog-actions>
        <md-button class="md-primary" @click="flightDialog = false">Close</md-button>
       
      </md-dialog-actions>
    </md-dialog>
    <md-dialog :md-active.sync="bulk.active" :style="[dialogDimensions]" :md-fullscreen="false" class="free-dialog scrollable-content-dialog">
     
      <md-dialog-title>
        {{bulk.title}}
      </md-dialog-title>
      <md-divider class=" mb-4">
      </md-divider>
      <div class="modal-up-content text-center" v-if="bulk.busy">
        <md-progress-spinner class="md-accent mb-3" :md-stroke="4" md-mode="indeterminate" ></md-progress-spinner>
      </div>
      
      <div class="modal-up-content" v-if="!bulk.busy">
        <div v-if="!bulk.available">
            <div class="md-subheading">
              <span>{{ bulk.error.message }}
              </span>
              <span class="up-text-confirmed">Paid</span> or <span class="up-text-caution">Partially paid</span>
              <p>Please refund or uncheck to continue bulk delete.</p>
            </div>
            <ul class="pl-0 mt-3">
              <li class="md-display-1 d-inline-block" v-for="(item, index) in bulk.deniedIds" v-bind:key="'denied-' + item">
                {{ bulk.deniedIds.length - 1 == index ? item : bulk.deniedIds.length == 1 ? item : item + ', ' }}  
              </li>
            </ul>
        </div>
        <div v-else>
            <div class="md-subheading">The following Ids will be deleted. This action can not be undone</div>
            <span class="md-title" v-for="(selelectedItem, index) in alternateToolBar.items" v-bind:key="'selelectedItem-' + selelectedItem.id">
                    {{ alternateToolBar.items.length - 1 == index ? selelectedItem.readableId : alternateToolBar.count == 1 ? selelectedItem.readableId : selelectedItem.readableId + ',' }}  
            </span>
            <div class="md-display-1 mt-3" :class="{'up-text-confirmed' : bulk.unlocked , 'up-text-cancelled': !bulk.unlocked}">  
              <md-icon v-if="bulk.available" class="md-icon-40" :class="{'up-text-confirmed' : bulk.unlocked , 'up-text-cancelled': !bulk.unlocked}">{{ bulk.unlocked ? 'lock_open' : 'lock_outline' }}</md-icon>
              {{ bulk.unlockCode}}
            </div>
            <md-field>
              <label>Type above code to unlock </label>
              <md-input v-model="bulk.unlockTry" @keyup="checkLock"></md-input>
            </md-field>
        </div>
      </div>
      <md-dialog-actions v-if="!bulk.busy">
        <md-button @click="bulk.active = false">Close</md-button>
        <md-button class="md-accent md-raised" @click="fireBulk" :disabled="!bulk.unlocked">{{ bulk.action }}</md-button>
      </md-dialog-actions>
    </md-dialog>
    <md-dialog :md-active.sync="map.active" :style="[dialogDimensions]" :md-fullscreen="false" class="free-dialog scrollable-content-dialog" >
      <md-dialog-title v-if="map.item">
        Reservation <span class="up-text-primary">{{map.item.readableId}}</span> route
         <div class="md-subheading" v-if="map.item">Trip distance: <span class="up-text-secondary">{{ map.item.miles}}</span> miles</div>
        
      </md-dialog-title>
       <md-divider class=" mb-4" v-show="false">
      </md-divider>
      <div class="modal-up-content text-center">
        <CRow >
          <CCol sm="12" md="12" lg="12" xl="7">
             
              <GmapMap :zoom="12"  style="width:100%;height:500px;min-width:300px"  ref="map"  :center="{lat:map.item ? map.item.pu.coordinates.lat : 0,lng: map.item ? map.item.pu.coordinates.lng : 0}" >
                  <DirectionsRenderer
                      travelMode="DRIVING"
                      :origin="origin"
                      :destination="destination"
                      :waypoints="computedWaypoints"
                      ref="road" />
              </GmapMap>
          </CCol>
        </CRow>

      </div>
      <md-dialog-actions>
        <md-button @click="closeMapDialog">Close</md-button>
       
      </md-dialog-actions>
    </md-dialog>
    <md-dialog :md-active.sync="driverAssignment.active" :style="[dialogDimensions]" :md-fullscreen="false" class="free-dialog scrollable-content-dialog" >
      <md-dialog-title >
         Reservation <b class="up-text-primary">ID: {{ driverAssignment.actualRes ? driverAssignment.actualRes.readableId : '' }} </b> driver assignment 
      </md-dialog-title>
       <md-divider class=" mb-4" v-show="false">
      </md-divider>
      <div class="modal-up-content text-center">
        <div v-if="driverAssignment.actualRes && driverAssignment.actualRes.offers && driverAssignment.actualRes.offers.length > 0">
          <md-table md-card class="mb-4" @md-selected="onOfferSelected" v-model="filteredPopOffers">
            <md-table-toolbar class="md-primary" >
              <h1 class="md-title text-left">Active offers</h1>
              <div class="md-toolbar-section-end" v-if="statuses[driverAssignment.actualRes.driverStatus] != 'Assigned'">
                <md-checkbox v-model="driverAssignment.offerFilters" value="Accepted">Accepted</md-checkbox>
                <md-checkbox v-model="driverAssignment.offerFilters" value="Offered">Offered</md-checkbox>
                <md-checkbox v-model="driverAssignment.offerFilters" value="Pre assigned">Pre assigned</md-checkbox>
              </div>
            </md-table-toolbar>
            <md-table-toolbar slot="md-table-alternate-header" slot-scope="{ count }">
              <div class="md-toolbar-section-start">{{ count }} {{ count == 1 ? 'offer' : 'offers' }} selected</div>

              <div class="md-toolbar-section-end">
                
                <!--md-button class="md-raised md-primary" v-if="count === 1">Assign job</md-button-->
                <md-button 
                    class="md-raised md-accent mr-0" 
                    @click="deleteOffers()"
                    :disabled="driverAssignment.busy" 
                    v-if="statuses[driverAssignment.selectedOffers[0].status] != 'Assigned' && statuses[driverAssignment.selectedOffers[0].status] != 'On hold'"
                >
                    Delete {{ count == 1 ? 'offer' : 'offers' }}
                    <md-progress-bar md-mode="indeterminate" v-show="driverAssignment.busy"></md-progress-bar>
                </md-button>
                <md-button 
                    class="md-raised md-primary mr-0 ml-2" 
                    v-if="statuses[driverAssignment.selectedOffers[0].status] === 'Accepted' && driverAssignment.selectedOffers.length === 1 "
                    :disabled="driverAssignment.busy" 
                    @click="assignDriver()"
                >
                    Assign user
                    <md-progress-bar md-mode="indeterminate" v-show="driverAssignment.busy"></md-progress-bar>
                </md-button>
                <md-button
                  class="md-raised md-primary mr-0 ml-2"
                  v-if="(statuses[driverAssignment.selectedOffers[0].status] === 'Assigned' || statuses[driverAssignment.selectedOffers[0].status] === 'On hold') && driverAssignment.selectedOffers.length === 1 "
                  :disabled="driverAssignment.busy"
                  @click="revokeAssignation()">
                   Revoke assignation
                    <md-progress-bar md-mode="indeterminate" v-show="driverAssignment.busy"></md-progress-bar>
                </md-button>
                
              </div>
            </md-table-toolbar>
            
              <md-table-row 
                slot="md-table-row" slot-scope="{ item }"
                class="text-left"
                md-selectable="multiple" 
                >
                <md-table-cell md-label="Name"  class="text-left white-space-no-wrap">
                    {{ item.name }} {{ item.lastname }}
                    <div class="mt-2">

                        <md-chip   v-if="item.tlc && item.tlc.check">
                            <md-icon class="mr-2 led-circle green pulse">circle</md-icon>
                            TLC Live: <b>{{item.tlc.check.type}}</b>
                        </md-chip>
                        <md-chip  v-if="item.tlc && item.tlc.check">
                            <md-icon class="mr-2 led-circle green pulse">circle</md-icon>
                            TLC Expiration: <b>{{item.tlc.check.expiration_date | moment('YYYY-MM-DD')}}</b>
                        </md-chip>
                    </div>
                  </md-table-cell>
              <md-table-cell md-label="Email">{{ item.email}}</md-table-cell>
              <md-table-cell md-label="Code">{{ item.code }}</md-table-cell>
              <md-table-cell md-label="Role" md-sort-by="role" class="text-left">
                        {{ item.subrole }} {{ item.role }}
              </md-table-cell>
              <md-table-cell md-label="Phone"  class="text-left">{{ item.phones.length > 0 ? item.phones[0] : '' }}</md-table-cell>
              <md-table-cell md-label="Status">
                <md-chip :class="[ statusClass(statuses[item.status])  ]">
                  {{ statuses[item.status]}}
                </md-chip>
              </md-table-cell>
              <md-table-cell md-label="Community">{{ item.community ? item.community.name : '-'}}</md-table-cell>
              <md-table-cell md-label="Assignation timeline" v-if="(statuses[driverAssignment.actualRes.driverStatus] == 'Assigned' || statuses[driverAssignment.actualRes.driverStatus] == 'On hold') && item.timeline">
                
                <md-table>
                  <md-table-row>
                    <md-table-head>Status</md-table-head>
                    <md-table-head>Step number</md-table-head>
                    <md-table-head>User in charge</md-table-head>
                    <md-table-head>Email</md-table-head>
                    <md-table-head>Phone</md-table-head>
                  </md-table-row>
                  <md-table-row v-for="(dot, index) in item.timeline" v-bind:key="index + '-table-mode-' + dot.userInCharge.uid">
                    <md-table-cell>
                      <md-chip :class="[ statusClass(statuses[dot.status])  ]">
                         Status: {{ statuses[dot.status] }}
                      </md-chip> 
                    </md-table-cell>
                    <md-table-cell>{{ index + 1 }}</md-table-cell>
                    <md-table-cell>{{ dot.userInCharge.name }} {{ dot.userInCharge.lastname }}</md-table-cell>
                    <md-table-cell>{{ dot.userInCharge.email }} </md-table-cell>
                    <md-table-cell>{{ dot.userInCharge.phone }} </md-table-cell>
                  </md-table-row>
                </md-table>
              </md-table-cell>
              
            
            </md-table-row>
          </md-table>  
          <md-divider class="mb-4"></md-divider>
        </div>
        <md-empty-state
              v-else
            class="md-accent"
            md-icon="list"
            md-label="No offers made yet"
            md-description="Add offers below to start driver assignment.">
        </md-empty-state>

        <div v-if="driverAssignment.actualRes && statuses[driverAssignment.actualRes.driverStatus] !== 'Assigned'">
          <div v-if="driverAssignment.newOffer">
            <div> 
              
              <h1 class="md-title text-left pl-4">Add offers</h1>
                          
              <md-tabs md-alignment="fixed">
                <md-tab id="tab-home" md-label="Drivers,companies and providers list" md-icon="list">
                  <md-toolbar class="md-dense mt-3 pl-4">
                      
                      
                      <md-switch v-model="driverAssignment.tlcOn" class="md-primary mr-4"  @change="tlcChanged" :disabled="driverAssignment.tlcBusy">
                       {{driverAssignment.tlcBusy ? 'Checking TLC' : 'Check TLC' }} 
                       <md-progress-bar md-mode="indeterminate" v-if="driverAssignment.tlcBusy"></md-progress-bar>
                      </md-switch>
                      <md-switch v-model="driverAssignment.dmvOn" class="md-primary"  @change="dmvChanged" :disabled="driverAssignment.dmvBusy">
                        {{driverAssignment.dmvBusy ? 'Checking Vehicles DMV' : 'Check DMV' }} 
                        <md-progress-bar md-mode="indeterminate" v-if="driverAssignment.dmvBusy"></md-progress-bar>
                        
                      </md-switch>
                      
                      
                  </md-toolbar>
                  <md-table 
                      v-if="driverAssignment.filteredLinkedDrivers.length > 0"
                      v-model="driverAssignment.filteredLinkedDrivers"
                      md-card 
                      @md-selected="onSelectAllDriverList"
                      class="alternate-header-position-relative selectable-table"
                        >
                    
                    <md-table-toolbar slot="md-table-alternate-header" slot-scope="{ count }">


                      <div class="md-toolbar-row">
                        <div class="md-toolbar-section-start">{{ count }} {{ count === 1 ? 'driver' : 'drivers' }} selected</div>


                        <div class="md-toolbar-section-end">
                        
                          <md-button 
                              class="md-primary md-raised" 
                              @click="sendOffer()"
                              :disabled="driverAssignment.busy" 
                              
                              >
                              {{ driverAssignment.busy ? 'Sending offer' : 'Send offer' }}
                              <md-progress-bar md-mode="indeterminate" v-show="driverAssignment.busy"></md-progress-bar>
                          </md-button>
                        </div>

                      </div>
                      <div class="md-toolbar-row">
                        <md-field class="mr-4" :class="{'md-invalid':getValidationClass('driverAmount')}">
                            <label>Driver amount ($)</label>
                            <md-input v-model="driverAssignment.actualRes.driverAmount" type="number"></md-input>
                            <span class="md-error" v-if="!$v.driverAssignment.actualRes.driverAmount.required">Amount is required</span>
                        </md-field>
                        <md-field >
                            <label>Offer comment</label>
                            <md-input v-model="driverAssignment.actualRes.offerComment" type="text"></md-input>
                        </md-field>
                      </div>
                      
                    </md-table-toolbar>
  
                    <md-table-row 
                        slot="md-table-row" 
                        slot-scope="{ item }"  
                        md-selectable="multiple" 
                        
                        :md-disabled="item.role == 'driver' && item.fleet.length == 0"
                      >
                      <md-table-cell md-label="Name"  class="text-left white-space-no-wrap">
                        {{ item.name }} {{ item.lastname }}
                        <div class="mt-2" v-if="driverAssignment.tlcOn">

                            <md-chip   v-if="item.tlc && item.tlc.check">
                                <md-icon class="mr-2 led-circle green pulse">circle</md-icon>
                                TLC Live: <b>{{item.tlc.check.type}}</b>
                            </md-chip>
                            <md-chip  v-if="item.tlc && item.tlc.check">
                                <md-icon class="mr-2 led-circle green pulse">circle</md-icon>
                                TLC Expiration: <b>{{item.tlc.check.expiration_date | moment('YYYY-MM-DD')}}</b>
                            </md-chip>
                        </div>
                      </md-table-cell>
                      <md-table-cell md-label="Code" md-sort-by="code" class="text-left">{{ item.code }}</md-table-cell>
                      <md-table-cell md-label="Role" md-sort-by="role" class="text-left">
                        {{ item.subrole }} {{ item.role }}
                      </md-table-cell>
                      <md-table-cell md-label="Phone"  class="text-left">{{ item.phones.length > 0 ? item.phones[0] : '' }}</md-table-cell>
                      <md-table-cell md-label="Email" md-sort-by="email" class="text-left">{{ item.email }}</md-table-cell>
                      <md-table-cell md-label="Areas" class="text-left">
                        <ul class="pl-0 mb-0">
                          <li v-for="(area,index) in item.areas" v-bind:key="area.formatted_address + index">
                            {{ area.formatted_address }}
                          </li>
                        </ul>
                      </md-table-cell>
                      <md-table-cell md-label="Fleet" class="text-left">
                        <md-field  :class="{'md-invalid': checkVehicleRequired(item) }" :id="'vehicle' + item.uid" v-if="item.selected && item.fleet.length > 0">
                            <label :for="'vehicle' + item.uid">Select driver vehicle</label>
                            <md-select @md-selected="onSelectedVehicle" v-model="item.requestedVehicle">
                                <md-option v-for="(vehicle,index) in item.fleet" v-bind:key="vehicle.name + '-selector-' + index" :value="index + '-' + item.id + '-' + item.index">
                                    {{ vehicle.name }} <b v-if="vehicle.vehicleType">({{ vehicle.vehicleType.name }})</b>
                                </md-option>
                            </md-select>
                            <span class="md-error">Vehicle is required</span>
                        </md-field>
                        <ul class="pl-0 mb-0"  v-if="!item.selected && item.fleet.length > 0">
                            <li v-for="(vehicle,index) in item.fleet" v-bind:key="vehicle.name + index"  class="white-space-no-wrap">
                                {{ vehicle.name }} <b v-if="vehicle.vehicleType">({{ vehicle.vehicleType.name }})</b>
                                <span class="mt-2 ml-2"  v-if="vehicle.dmvCheck && driverAssignment.dmvOn">

                                  <md-chip  >
                                      <md-icon class="mr-2 led-circle green pulse">circle</md-icon>
                                      DMV Active: <b>{{vehicle.dmvCheck.active}}</b>
                                  </md-chip>
                                  <md-chip  >
                                      <md-icon class="mr-2 led-circle green pulse">circle</md-icon>
                                    Base name: <b>{{vehicle.dmvCheck.base_name }}</b>
                                  </md-chip>
                                  <md-chip  >
                                      <md-icon class="mr-2 led-circle green pulse">circle</md-icon>
                                    Base type: <b>{{vehicle.dmvCheck.base_type }}</b>
                                  </md-chip>
                                  <md-chip  >
                                      <md-icon class="mr-2 led-circle green pulse">circle</md-icon>
                                    Vehicle: <b>{{vehicle.dmvCheck.vehicle_year }}</b>
                                  </md-chip>
                                </span>
                            </li>
                        </ul>
                        <!--ul class="pl-0 mb-0">
                          <li v-for="(vehicle,index) in item.fleet" v-bind:key="vehicle.name + index">
                            {{ vehicle.name }} <b v-if="vehicle.vehicleType">({{ vehicle.vehicleType.name }})</b>
  
                          </li>
                        </ul-->


                        
                      </md-table-cell>
                      
                    </md-table-row>
                  </md-table>
                  <md-empty-state
                     v-else
                    class="md-accent"
                    md-icon="car_crash"
                    md-label="No more available drivers"
                    md-description="There no more possible owner operators or company drivers to offer.">
                  </md-empty-state>
                 
                </md-tab>
                <md-tab id="tab-pages" md-label="Communities" md-icon="groups_3">
                  <md-table v-if="driverAssignment.communities.length > 0" v-model="driverAssignment.communities" md-card @md-selected="onSelectCommunity" class="alternate-header-position-relative">
                    
                    <md-table-toolbar slot="md-table-alternate-header" slot-scope="{ count }">
                      <div class="md-toolbar-row">

                        <div class="md-toolbar-section-start">{{ count }} {{ count === 1 ? 'community' : 'communities'}} selected</div>
    
                        <div class="md-toolbar-section-end">
                          
                          <md-button class="md-primary md-raised" @click="sendCommunitiesOffers" :disabled="driverAssignment.busy">
                           
                            {{ driverAssignment.busy ? 'Sending offers' : 'Send offers' }}
                              <md-progress-bar md-mode="indeterminate" v-show="driverAssignment.busy"></md-progress-bar>
                          </md-button>
                        </div>
                      </div>
                      <div class="md-toolbar-row">
                        <md-field class="mr-4" :class="{'md-invalid':getValidationClass('driverAmount')}">
                              <label>Driver amount ($)</label>
                              <md-input v-model="driverAssignment.actualRes.driverAmount" type="number"></md-input>
                              <span class="md-error" v-if="!$v.driverAssignment.actualRes.driverAmount.required">Amount is required</span>
                          </md-field>
                          <md-field>
                              <label>Offer comment</label>
                              <md-input v-model="driverAssignment.actualRes.offerComment" type="text"></md-input>
                          </md-field>
                        
                      </div>
                    </md-table-toolbar>
  
                    <md-table-row 
                      slot="md-table-row" slot-scope="{ item }" 
                       md-selectable="multiple" 
                       md-auto-select 
                       :md-disabled="driverAssignment.actualRes.offeredCommunities && driverAssignment.actualRes.offeredCommunities.includes(item.id)">
                      <md-table-cell md-label="Name" class="text-left">
                        {{ item.name }}
                      </md-table-cell>

                      <md-table-cell md-label="Status" class="text-left">
                        {{ driverAssignment.actualRes.offeredCommunities && driverAssignment.actualRes.offeredCommunities.includes(item.id) ? 'Already used' : 'Available to offer'}}
                      </md-table-cell>
                     
                      
                      
                    </md-table-row>
                  </md-table>
                </md-tab>
                <md-tab id="tab-posts" md-label="Custom search" md-icon="travel_explore" v-if="false">
                  
                  <md-toolbar class="md-dense">
                    <div class="md-toolbar-row">
                      <div class="md-toolbar-section-start">
                        <div class="ml-0 md-title">Driver filter</div>
                      </div>
  
                      <div class="md-toolbar-section-end">
                        <md-button class="md-raised md-dense md-primary" @click="performQuery()" :disabled="driverAssignment.searching">
                          Apply driver filter
                        </md-button>
  
                        <md-button class="md-icon-button">
                          <md-icon>more_vert</md-icon>
                        </md-button>
                      </div>
                    </div>
  
                    <div class="md-toolbar-row">
                      <CContainer>
  
                        <CRow>
                          <CCol md="6">
                            <md-field :class="{'md-invalid':getValidationClass('vehicleType')}">
                              <label>Vehicle type</label>
                              <md-select v-model="driverAssignment.vehicleType.id" :disabled="driverAssignment.searching">
                                  <md-option v-for="vehicleType in baseVehicleTypes" :value="vehicleType.id" v-bind:key="'filter-vehicle-' + vehicleType.id">{{vehicleType.name}}</md-option>
                              </md-select>
                              <span class="md-error" v-if="!$v.driverAssignment.vehicleType.id.required">Field is required</span>
                            </md-field>
                            
                          </CCol>
                          <CCol md="6">
                            <md-field :class="{'md-focused':driverAssignment.zoneInputFocused,'md-invalid':getValidationClass('fencesList')}">
                              <label>Type zone</label>
                              
                              
                              
                              <gmap-autocomplete
                                  ref="autocomplete"
                                  placeholder=""
                                  @place_changed="setFence($event)" class="pu-autocomplete"
                                  @blur="resetCursor()"
                                  @focus="locateCursor()"
                              
                                  :disabled="driverAssignment.searching"
                                  
                                  :types="mapTypes"
                                  >
                              </gmap-autocomplete>
                              <!--span class="md-helper-text">{{zoneQuery ? 'Type place, city, state, country' : 'e.g. COA-1003, companyname@mail.com'}}</span>
                              <span class="md-error" v-if="!$v.searchTerm.required">Field is required</span-->
                              <span class="md-error" v-if="!$v.coveredArea.fencesList.required">Field is required</span>
                            </md-field>
    
                          </CCol>
                        </CRow>
                      </CContainer>
                    </div>
                  </md-toolbar>
                  <md-progress-bar class="md-accent mb-3" :md-stroke="4" md-mode="indeterminate" v-if="driverAssignment.searching"></md-progress-bar>
                  <md-table v-if="driverAssignment.queryResults.length > 0 && !driverAssignment.searching" v-model="driverAssignment.queryResults" @md-selected="onSelectCustomSearch">
                    <md-table-toolbar slot="md-table-alternate-header" slot-scope="{ count }">
                      <div class="md-toolbar-section-start">{{ count }} {{ count === 1 ? 'driver' : 'drivers'}} selected</div>
  
                      <div class="md-toolbar-section-end">
                        <md-button class="md-primary md-raised">
                          Send offer
                        </md-button>
                      </div>
                    </md-table-toolbar>
                    <md-table-row 
                          slot="md-table-row" 
                          slot-scope="{ item }" 
                          :md-disabled="item.exists" md-selectable="multiple" md-auto-select>
                          <md-table-cell md-label="Name" md-sort-by="auth.displayName">
                              {{ item.auth.displayName }}
                          </md-table-cell>
                          <md-table-cell md-label="Email" md-sort-by="auth.email">
                              {{ item.auth.email }}
                              <md-chip v-if="item.exists" class="md-primary mt-2">Already linked</md-chip>
                          </md-table-cell>
                          <md-table-cell md-label="Phones">
                              <ul class="table-inner-list">
                                  <template v-for="profile in item.profiles" >
                                      <li v-for="phone in profile.data.phones" v-bind:key="phone">
                                          {{ phone }}
                                      </li>
                                  </template>
                                  
                              </ul>
                          </md-table-cell>
                          <md-table-cell md-label="Areas">
                              <ul class="table-inner-list">
                                  <template v-for="profile in item.profiles" >
                                      <li v-for="matchedArea in profile.matchedAreas" v-bind:key="matchedArea.name">
                                          {{ matchedArea.name }}
                                      </li>
                                  </template>
                                  
                              </ul>
                          </md-table-cell>
                          <md-table-cell  md-label="Role" md-sort-by="auth.customClaims.role">{{item.auth.customClaims.role}}</md-table-cell>
                          <md-table-cell  md-label="Code" md-sort-by="auth.customClaims.code">{{item.auth.customClaims.code}}</md-table-cell>
                          <md-table-cell  md-label="Matched partner profiles" >{{item.profiles.length}}</md-table-cell>
                          <md-table-cell md-label="Action">
                          <md-button class="md-raised" @click="viewRefMap(item)" v-if="!zonePreview.showMap">
                              <md-icon class="mr-2">map</md-icon>
                              View area map</md-button>
                              <md-button class="md-raised" @click="hideRefMap()" v-if="zonePreview.showMap">
                              <md-icon class="mr-2">map</md-icon>
                              Hide area map</md-button>
                          
                          </md-table-cell>
                      </md-table-row>
                  </md-table>
                </md-tab>
              </md-tabs>
            </div>
          </div>
        </div>        
          
      </div>
      <md-dialog-actions>
        <md-button @click="driverAssignment.active = false">Close</md-button>
      </md-dialog-actions>
    </md-dialog>
    <md-dialog :md-active.sync="directAssignment.active" :style="[dialogDimensions]" :md-fullscreen="false" class="free-dialog scrollable-content-dialog">
      <md-dialog-title >
        <div >Direct assignment</div>
        <div class="md-subheading">Reservation <b class="up-text-primary">ID: {{ directAssignment.actualRes ? directAssignment.actualRes.readableId : '' }} </b>  </div> 
      </md-dialog-title>
       <md-divider class=" mb-4" v-show="false">
      </md-divider>
      <div class="modal-up-content text-center">

        <md-toolbar md-elevation="1" v-if="directAssignment.selectedDriver">
          <div class="md-toolbar-row">
            <div class="md-toolbar-section-start">
                
                <div class="md-title">{{ directAssignment.selectedDriver.email }} ({{ directAssignment.selectedDriver.code }})</div>
            </div>

            <div class="md-toolbar-section-end">
            
            
              <md-button 
                  class="md-primary md-raised" 
                  @click="sendDirectAssigment()"
                  :disabled="directAssignment.busy" 
                  
                  >
                  {{ directAssignment.busy ? 'Sending direct assignment' : 'Send direct assignment' }}
                  <md-progress-bar md-mode="indeterminate" v-show="directAssignment.busy"></md-progress-bar>
              </md-button>
            </div>
          </div>
          <div class="md-toolbar-row">
            <md-field class="mr-4" >
                <label>Driver amount ($)</label>
                <md-input v-model="directAssignment.actualRes.driverAmount" type="number"></md-input>
                
            </md-field>
            <md-field >
                <label>Offer comment</label>
                <md-input v-model="directAssignment.actualRes.offerComment" type="text"></md-input>
            </md-field>
          </div>
        </md-toolbar>

        <!---->
        <div v-if="directAssignment.actualRes && directAssignment.actualRes.offers && directAssignment.actualRes.offers.length > 0">
          <md-table md-card class="mb-4 alternate-header-position-relative" @md-selected="onDirectOfferSelected" v-model="filteredDirectOffers">
            <md-table-toolbar class="md-primary" >
              <h1 class="md-title text-left">Active direct assignment</h1>
              
            </md-table-toolbar>
            <md-table-toolbar slot="md-table-alternate-header" slot-scope="{ count }">
              <div class="md-toolbar-row">

                <div class="md-toolbar-section-start">{{ count }} {{ count == 1 ? 'offer' : 'offers' }} selected</div>
  
                <div class="md-toolbar-section-end">
                  
                  <!--md-button class="md-raised md-primary" v-if="count === 1">Assign job</md-button-->
                  <md-button 
                      class="md-raised md-accent mr-0" 
                      @click="deleteAssignation()"
                      :disabled="directAssignment.busy" 
                      v-if="statuses[directAssignment.selectedOffers[0].status] != 'Assigned' && statuses[directAssignment.selectedOffers[0].status] != 'On hold'"
                  >
                      Delete direct assignation
                      <md-progress-bar md-mode="indeterminate" v-show="directAssignment.busy"></md-progress-bar>
                  </md-button>
                  <md-button 
                      class="md-raised md-primary mr-0 ml-2" 
                      v-if="statuses[directAssignment.selectedOffers[0].status] === 'Accepted' && directAssignment.selectedOffers.length === 1 "
                      :disabled="directAssignment.busy" 
                      @click="assignDriver()"
                  >
                      Assign user
                      <md-progress-bar md-mode="indeterminate" v-show="directAssignment.busy"></md-progress-bar>
                  </md-button>
                  <md-button
                    class="md-raised md-primary mr-0 ml-2"
                    v-if="(statuses[directAssignment.selectedOffers[0].status] === 'Assigned' || statuses[directAssignment.selectedOffers[0].status] === 'On hold') && directAssignment.selectedOffers.length === 1 "
                    :disabled="directAssignment.busy"
                    @click="revokeDirectAssignation()">
                     Revoke assignation
                      <md-progress-bar md-mode="indeterminate" v-show="directAssignment.busy"></md-progress-bar>
                  </md-button>
                  
                </div>
              </div>
             
            </md-table-toolbar>
            
              <md-table-row 
                slot="md-table-row" slot-scope="{ item }"
                class="text-left"
                md-selectable="multiple"
                 >
              
              <md-table-cell md-label="Email">{{ item.email}}</md-table-cell>
              <md-table-cell md-label="Code">{{ item.code }}</md-table-cell>
              <md-table-cell md-label="Role" md-sort-by="role" class="text-left">
                        {{ item.subrole }} {{ item.role }}
              </md-table-cell>
              <md-table-cell md-label="Phone"  class="text-left">{{ item.phones.length > 0 ? item.phones[0] : '' }}</md-table-cell>
              <md-table-cell md-label="Requested vehicle">
                                        
                  <div v-if="item.requestedVehicle">
                      <div class="md-layout passenger-layout md-alignment-center-left flex-wrap-no">
                          <div class="md-layout-item">
                              <p class="white-space-no-wrap pr-4 mb-0">
                                  {{  item.requestedVehicle.name }} ({{ item.requestedVehicle.vehicleType.name}})
                              </p>
                              
                          
                          </div>
                          <div class="md-layout-item">
                              <div class="qty-container">
                                  <md-icon>emoji_people</md-icon>
                                  <span class="big-qty">{{item.requestedVehicle.passengers}}</span>
                              </div>
                              <div class="qty-container">
                                  <md-icon>luggage</md-icon>
                                  <span class="big-qty">{{item.requestedVehicle.luggage}}</span>
                              </div>
                          
                          </div>
                          
                      </div>
                      
                      
                  </div>
                  <div v-else>
                      -
                  </div>
                  
              </md-table-cell>
              <md-table-cell md-label="Status">
                <md-chip :class="[ statusClass(statuses[item.status])  ]">
                  {{ statuses[item.status]}}
                </md-chip>
              </md-table-cell>
              <md-table-cell md-label="Community">{{ item.community ? item.community.name : '-'}}</md-table-cell>
              <md-table-cell md-label="Assignation timeline" v-if="(statuses[directAssignment.actualRes.driverStatus] == 'Assigned' || statuses[directAssignment.actualRes.driverStatus] == 'On hold') && item.timeline">
                
                <md-table>
                  <md-table-row>
                    <md-table-head>Status</md-table-head>
                    <md-table-head>Step number</md-table-head>
                    <md-table-head>User in charge</md-table-head>
                    <md-table-head>Email</md-table-head>
                    <md-table-head>Phone</md-table-head>
                  </md-table-row>
                  <md-table-row v-for="(dot, index) in item.timeline" v-bind:key="index + '-table-mode-' + dot.userInCharge.uid">
                    <md-table-cell>
                      <md-chip :class="[ statusClass(statuses[dot.status])  ]">
                         Status: {{ statuses[dot.status] }}
                      </md-chip> 
                    </md-table-cell>
                    <md-table-cell>{{ index + 1 }}</md-table-cell>
                    <md-table-cell>{{ dot.userInCharge.name }} {{ dot.userInCharge.lastname }}</md-table-cell>
                    <md-table-cell>{{ dot.userInCharge.email }} </md-table-cell>
                    <md-table-cell>{{ dot.userInCharge.phone }} </md-table-cell>
                  </md-table-row>
                </md-table>
              </md-table-cell>
              
            
            </md-table-row>
          </md-table>  
          <md-divider class="mb-4"></md-divider>
        </div>
        <!---->
        <md-toolbar class="md-dense mt-3 pl-4">
            
            
            <md-switch v-model="directAssignment.tlcOn" class="md-primary mr-4"  @change="directTlcChanged" :disabled="directAssignment.tlcBusy">
              {{directAssignment.tlcBusy ? 'Checking TLC' : 'Check TLC' }} 
              <md-progress-bar md-mode="indeterminate" v-if="directAssignment.tlcBusy"></md-progress-bar>
            </md-switch>
            <md-switch v-model="directAssignment.dmvOn" class="md-primary"  @change="directDmvChanged" :disabled="directAssignment.dmvBusy">
              {{directAssignment.dmvBusy ? 'Checking Vehicles DMV' : 'Check DMV' }} 
              <md-progress-bar md-mode="indeterminate" v-if="directAssignment.dmvBusy"></md-progress-bar>
            </md-switch>
            
            
        </md-toolbar>
        <md-table 
            v-if="directAssignment.filteredLinkedDrivers.length > 0 && directAssignment.actualRes && !directAssignment.actualRes.directAssignment"
             v-model="directAssignment.filteredLinkedDrivers" 
             md-card 
             @md-selected="onSelectDirectAssignationList"
             class="selectable-table">
                    

          <md-table-row slot="md-table-row" slot-scope="{ item }"  md-selectable="multiple" :md-disabled="(item.role == 'driver' && item.fleet.length == 0) || item.disabled">
            <md-table-cell md-label="Name" md-sort-by="lastname" class="text-left">
                {{ item.name }} {{ item.lastname }}
                
                    <span class="mt-2" v-if="directAssignment.tlcOn">
                        <md-chip   v-if="item.tlc && item.tlc.check">
                            <md-icon class="mr-2 led-circle green pulse">circle</md-icon>
                            TLC Live: <b>{{item.tlc.check.type}}</b>
                        </md-chip>
                        <md-chip  v-if="item.tlc && item.tlc.check">
                            <md-icon class="mr-2 led-circle green pulse">circle</md-icon>
                            TLC Expiration: <b>{{item.tlc.check.expiration_date | moment('YYYY-MM-DD')}}</b>
                        </md-chip>
                    </span>
                
            </md-table-cell>
            <md-table-cell md-label="Code" md-sort-by="code" class="text-left">{{ item.code }}</md-table-cell>
            <md-table-cell md-label="Role" md-sort-by="role" class="text-left">
              {{ item.subrole }} {{ item.role }}
            </md-table-cell>
            <md-table-cell md-label="Phone"  class="text-left">{{ item.phones.length > 0 ? item.phones[0] : '' }}</md-table-cell>
            <md-table-cell md-label="Email" md-sort-by="email" class="text-left">{{ item.email }}</md-table-cell>
            <md-table-cell md-label="Areas" class="text-left">
              <ul class="pl-0 mb-0">
                <li v-for="(area,index) in item.areas" v-bind:key="area.formatted_address + index">
                  {{ area.formatted_address }}
                </li>
              </ul>
            </md-table-cell>
            <md-table-cell md-label="Fleet" class="text-left">
              <md-field  :class="{'md-invalid': checkVehicleRequired(item) }" :id="'direct-vehicle' + item.uid" v-if="item.selected && item.fleet.length > 0">
                  <label :for="'vehicle' + item.uid">Select driver vehicle</label>
                  <md-select @md-selected="onSelectedVehicle" v-model="item.requestedVehicle">
                      <md-option v-for="(vehicle,index) in item.fleet" v-bind:key="vehicle.name + '-selector-' + index" :value="index + '-' + item.id + '-' + item.index">
                          {{ vehicle.name }} <b v-if="vehicle.vehicleType">({{ vehicle.vehicleType.name }})</b>
                      </md-option>
                  </md-select>
                  <span class="md-error">Vehicle is required</span>
              </md-field>
              <ul class="pl-0 mb-0"  v-if="!item.selected && item.fleet.length > 0">
                  <li v-for="(vehicle,index) in item.fleet" v-bind:key="vehicle.name + index"  class="white-space-no-wrap">
                      {{ vehicle.name }} <b v-if="vehicle.vehicleType">({{ vehicle.vehicleType.name }})</b>
                      <span class="mt-2 ml-2"  v-if="vehicle.dmvCheck && directAssignment.dmvOn">

                        <md-chip  >
                            <md-icon class="mr-2 led-circle green pulse">circle</md-icon>
                            DMV Active: <b>{{vehicle.dmvCheck.active}}</b>
                        </md-chip>
                        <md-chip  >
                            <md-icon class="mr-2 led-circle green pulse">circle</md-icon>
                        Base name: <b>{{vehicle.dmvCheck.base_name }}</b>
                        </md-chip>
                        <md-chip  >
                            <md-icon class="mr-2 led-circle green pulse">circle</md-icon>
                        Base type: <b>{{vehicle.dmvCheck.base_type }}</b>
                        </md-chip>
                        <md-chip  >
                            <md-icon class="mr-2 led-circle green pulse">circle</md-icon>
                        Vehicle: <b>{{vehicle.dmvCheck.vehicle_year }}</b>
                        </md-chip>
                      </span>
                      
                  </li>
              </ul>
            </md-table-cell>
            
          </md-table-row>
        </md-table>
        
      </div>
      <md-dialog-actions>
        <md-button @click="directAssignment.active = false">Close</md-button>
      </md-dialog-actions>
    </md-dialog>
   
   
  </div>
</template>

<script>
import VueTimepicker from 'vue2-timepicker'
import Vuetable from 'vuetable-2'
import CTableWrapper from '../base/Table'
//import VueTimepicker from 'vue2-timepicker'
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import FieldsDef from "./FieldsDef";
import firebase from '../../Firebase'
import axios from "./../../helpers/axios";
import newAxios from "axios"

import DirectionsRenderer from './../maps/DirectionsRenderer';
import moment from 'moment'
import moment_timezone from "moment-timezone";
import { validationMixin } from 'vuelidate';

import * as VueGoogleMaps from 'vue2-google-maps'
import {
    required,
    between,
    email,
    minLength,
    maxLength,
    requiredIf
} from 'vuelidate/lib/validators'

const toLower = text => {
  return text.toString().toLowerCase()
}

const searchByName = (items, term) => {
  if (term) {
    //return items.filter(item => toLower(item.readableId).includes(toLower(term)))
    return items.filter(item => {
     
      return  toLower(item.readableId).includes(toLower(term)) || 
              toLower(item.passenger.phone).includes(toLower(term)) || 
              toLower(item.passenger.firstName).includes(toLower(term)) || 
              toLower(item.passenger.lastName).includes(toLower(term)) || 
              toLower(item.passenger.email).includes(toLower(term));
    })
  }

  return items
}


export default {
  name: 'Reservations',
  components: { CTableWrapper, Vuetable, VuetablePagination, DirectionsRenderer,VueTimepicker },
  mixins: [validationMixin],
  data() {
    return {
      mainToolbarContracted : true,
      duplicatedPaginator: null,
      options: null,
      triggeredByExternal: false,
      rowsPerPage: 20,
      test: '1680318000600',
      map: {
        title: null,
        active: false,
        item: null,
        
      },
      bulk: {
        title: null,
        available: false,
        active:false,
        error: {
          title: null,
          message: null
        },
        confirmed: false,
        action: null,
        deniedIds: [],
        unlockCode: null,
        unlockTry: null,
        unlocked: false,
        busy: false

      },
      alternateToolBar: {
        active: false,
        count: 0,
        items: [],
        
      },
      actualFlightPack:null,
      flightDialog: false,
      isBusy:true,
      // filters: {
      //   fromDate: null,
      //   toDate: null,
      //   queryFromDate: null,
      //   queryToDate: null,
      //   rowsQty:10,
      //   search:null,
      //   companyProfile:null,
      //   perPage: 30,
      //   page: 1,
      //   sort: null,
      //   sortOrder: null,
      //   preset: 'today',
      //   searchById: false,
      //   goToID: null
      // },
      modalInfo: {
        type: null,
        resId: null,
        daytime: {
          day: null,
          time: null
        },
        passenger: {
          firstName:null,
          lastName:null,
          email: null,
          phone:null,
          qty:null,
          luggage:null
        },
        reservation: null
      },
      dialogLeft: 0,
      showDialog:false,
      showSnackbar: false,
      snackMessage: null,
      duration: 8000,
      isInfinity: false,
      actualSaving: {
        index: null,
        col: null,
        busy: false
      },
      fields:FieldsDef,
      //reservationStatuses: [],
      //driverStatuses: [],
      //paymentStatuses: [],
      //tripStatuses: [],
      //reservations: [],
      reservations: {
        mdCount: null,
        mdPage: null,
        mdData: []
      },
      dispatchers: [],
      addons: [],
      //vehicleTypes: [],
      baseVehicleTypes: [],
      //companyProfiles: [],
      //jobTypes: [],
      ref: this.$firebase.firestore().collection('users').doc(this.$root.uid).collection('reservations'),
      dispatchRef:  this.$firebase.firestore().collection('dispatchers'),
      vehicleRef:  this.$firebase.firestore().collection('users').doc(this.$root.uid).collection('vehicle-types'),
      jobTypesRef:  this.$firebase.firestore().collection('job-types'),
      statusesRef: this.$firebase.firestore().collection('statuses'),
      addonsRef: this.$firebase.firestore().collection('users').doc(this.$root.uid).collection('add-ons'),
      css: {
        table: {
          tableWrapper: 'up-table-wrapper',
          tableClass: 'up-table',
        }
      },
      masterTableStyle:{ height: '600px'},
      tableFixedColumns: {
        data: null,
        show: false
      },
      tableContentHeight: null,
      fixedColShadowed: false,
      unsub: null,
      openedFromDate: false,
      openedToDate: false,
      directAssignment: {

        actualReservationId: null,
        active: false,
        actualRes: null,
        linkedAvailableUsersToOffer: [],
        companyDrivers: [],
        filteredLinkedDrivers: [],
        selectedDriver: null,
        selectedOffers: [],
        dmvOn: false,
        tlcOn: false,
        dmvBusy: false,
        tlcBusy: false
      },
      driverAssignment: {
        auxModal:false,
        actualRes: null,
        busy: false,
        active: false,
        direct: false,
        newOffer: true,
        linkedAvailableUsersToOffer: [],
        companyDrivers: [],
        filteredLinkedDrivers: [],
        communities: [],
        selectedDriver: null,
        selectedDriverText: null,
        selectedCommunity: null,
        queryResults: [],
        zoneInputFocused: false,
        vehicleType: {
          id: null
        },
        searchTerm: null,
        searching: false,
        actualReservationId: null,
        selectedDrivers: [],
        selectedCommunities: [],
        selectedCustomSearch: [],
        selectedOffers: [],
        offerFilters: [
          'Accepted'
        ],
        dmvOn: false,
        tlcOn: false,
        dmvBusy: false,
        tlcBusy: false

        


      },
      mapTypes: [
          'political'
          // 'country', 
          // 'administrative_area_level_1', 
          // 'administrative_area_level_2',
          // 'administrative_area_level_3',
          // 'locality',
          // 'sublocality_level_1',
      ],
      coveredArea: {
          paths: [
              // [
              //     { lat: 25.774, lng: -80.19 },
              //     { lat: 18.466, lng: -66.118 },
              //     { lat: 32.321, lng: -64.757 }
              // ],
              // [
              //     { lat: 23.774, lng: -80.19 },
              //     { lat: 12.466, lng: -66.118 },
              //     { lat: 28.321, lng: -64.757 }
              // ]
          ],
          zoom:4,
          coordinates: { 
              lat: 24.886, lng: -70.268 
          },
          fencesList: []

      },
      geofence: {
          name: null,
          code:null,
          path: [],
          zoom:7,
          center: {lat: 1.38, lng: 103.8},
      },
      zonePreview: {
          showMap:false,
          searched: {
              geometry: {
                  lat: null,
                  lng: null
              },
              paths: [],
              show: true

          },
          matched: [],
          showMatched: true,
          user: null,
          profiles: []
      },
      dateLoaded: false,
      firedFromPreset: false,
      unsubscribeLiveChanges: null,
      request: null
    }
  },
  computed: {
    filteredDirectOffers() {
      let offers = []
      let th = this

      if(this.directAssignment.actualRes 
        && this.statuses[this.directAssignment.actualRes.driverStatus] == 'Assigned' || this.statuses[this.directAssignment.actualRes.driverStatus] == 'Pre assigned')
      {
        offers = this.directAssignment.actualRes.offers.filter(function(offer){
          if(th.statuses[offer.status] == 'Assigned' || th.statuses[offer.status] == 'Pre assigned' ) {
            return true
          }
        })
      }
      return offers

    },
    filteredPopOffers() {
      let offers = []
      let th = this
      
      if(this.driverAssignment.actualRes 
        && this.statuses[this.driverAssignment.actualRes.driverStatus] == 'Assigned' || this.statuses[this.driverAssignment.actualRes.driverStatus] == 'On hold')
      {
        offers = this.driverAssignment.actualRes.offers.filter(function(offer){
          if(th.statuses[offer.status] == 'Assigned' || th.statuses[offer.status] == 'On hold' ) {
            return true
          }
        })
      } else if(this.driverAssignment.actualRes){
        offers = this.driverAssignment.actualRes.offers.filter(function(offer){
          if(th.driverAssignment.offerFilters.includes(th.statuses[offer.status])) {
            return true
          }
        })
      }
      // if(this.driverAssignment.actualRes.offers) {
      //   for (let j = 0; j < array.length; j++) {
      //     const element = array[j];
          
      //   }
      // }
      return offers
    },
    origin() {
        if (this.map.item) {
          if (!this.map.item.pu) return null;
          return { query: this.map.item.pu.coordinates.lat + ',' + this.map.item.pu.coordinates.lng };
        } else {
          return null
        }

       
    },
    destination() {
        if(this.map.item){
            if (!this.map.item.do) return null;
            return { query: this.map.item.do.coordinates.lat + ',' + this.map.item.do.coordinates.lng };
        } else {
          return null
        }
        
    },
    computedWaypoints() {
        if(this.map.item){

          if (this.map.item.waypoints.length == 0 ) return null;
          const total = [];
          this.map.item.waypoints.forEach((waypoint,index) => {
              if(!waypoint.stop){
                  //total.push(null);
              } else {
                  total.push({
                      stopover:true,
                      location: !waypoint.stop ? null : waypoint.stop.location
                  })

                  
              }
              
          });
          return total;

        } else {
          return null
        }
       
        
    }, 
    dialogDimensions () {
        return {
            //left: this.$parent.$parent.offsetLeft + 'px'
            left: this.$store.state.dialogLeft + 'px' ,
            width: this.$store.state.dialogWidth + 'px'
            //top: (this.$store.state.sidebarNavHeight / 2) + 'px'
        }
    },
    dialogWidth() {
        return this.$store.state.dialogWidth
    },
    statuses() {
      // TODO: Statuses computed
      return this.$store.state.statuses;
    },
    user() {
      return this.$store.state.user;
    },
    timezone() {
      return this.$store.state.timezone;
    },
    fromDateString:{
      get(){
        
        return String(this.filters.fromDate)
      },
      async set(newFromDate) {
        //
        this.$store.commit('setDateFilter', ['fromDate',parseInt(newFromDate)])
        this.$store.commit('setDateFilter', ['preset','custom'])
        this.$store.commit('setDateFilter', ['page',1])
        this.isBusy = true
        this.triggeredByExternal = true;
        //this.updatePagination(this.filters.page, this.filters.perPage)
        await this.fireQuery()
        this.isBusy = false
      }
    },
    toDateString:{
      get(){
      
        return String(this.filters.toDate)
      },
      async set(newToDate) {
        this.$store.commit('setDateFilter', ['toDate',parseInt(newToDate)])
        this.$store.commit('setDateFilter', ['preset','custom'])
        this.$store.commit('setDateFilter', ['page',1])
        this.isBusy = true
        this.triggeredByExternal = true;
        //this.updatePagination(this.filters.page, this.filters.perPage)
        await this.fireQuery()
        this.isBusy = false
      }
    },
    filters() {
      return this.$store.state.filters;
    },
    filteredReservationsList: {
      get(){
        if(this.$refs.paginator) {
          //this.duplicatedPaginator = this.$refs.paginator.$el.outerHTML
        }
        if(this.filters.search) {
          let result =  searchByName(this.reservations.mdData, this.filters.search)
          this.$nextTick(function(){
            this.updateTableHeight()
            this.updateTableFixedColumnsData()
            if(window.document.querySelectorAll('.up-table')[0]) {
              window.document.querySelectorAll('.up-table')[0].addEventListener('scroll', this.onTableScrollHorizontal)
            }
            if( window.document.querySelectorAll('body.uplimo-body .md-table.md-theme-default .md-table-content')[0]) {
             window.document.querySelectorAll('body.uplimo-body .md-table.md-theme-default .md-table-content')[0].addEventListener('scroll', this.onTableScrollVertical)
            }
          })
          return result
        } else {
          let result = this.reservations.mdData
          this.$nextTick(function(){
            this.updateTableHeight()
            this.updateTableFixedColumnsData()
            if(window.document.querySelectorAll('.up-table')[0]) {
              window.document.querySelectorAll('.up-table')[0].addEventListener('scroll', this.onTableScrollHorizontal)
            }
            if( window.document.querySelectorAll('body.uplimo-body .md-table.md-theme-default .md-table-content')[0]) {
              window.document.querySelectorAll('body.uplimo-body .md-table.md-theme-default .md-table-content')[0].addEventListener('scroll', this.onTableScrollVertical)
            }
          })
          return result
        }
        
      },
      set(newList){
        return newList
      }
      
    },
    reservationStatuses() {
        return this.$store.state.reservationStatuses;
    },
    driverStatuses() {
        return this.$store.state.driverStatuses;
    },
    paymentStatuses() {
        return this.$store.state.paymentStatuses;
    },
    tripStatuses() {
        return this.$store.state.tripStatuses;
    },
    serviceTypes() {
        return this.$store.state.serviceTypes;
    },
    vehicleTypes() {
        return this.$store.state.vehicleTypes;
    },
    jobTypes() {
        return this.$store.state.jobTypes;
    },
    companyProfiles() {
        return this.$store.state.companyProfiles;
    },
  },
  beforeCreate(){
  },
  created() {
      
      window.addEventListener('resize', this.myEventHandler);      
      
      
  },
  destroyed() {
        if(this.unsub) {
          
          this.unsub()
        }

        if(this.unsubscribeLiveChanges) {
          this.unsubscribeLiveChanges()
        }
        window.removeEventListener('resize', this.myEventHandler);
        this.$store.commit('restoreBrowserTime')
  },
  mounted () {
    this.obtainViewResources();
    this.$store.commit('centerDialog',window.innerWidth)
  },
  watch: {
    $route: {
      immediate: true,
      handler (route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    },
    '$store.state.sidebarMinimize': function() {
      this.$store.commit('centerDialog',window.innerWidth)
    },
    '$store.state.sidebarShow': function(){
      this.$store.commit('centerDialog',window.innerWidth)
    },
    'filters.fromDate': {
      //this.fireFilters()
      handler(val,oldVal) {

            if(this.dateLoaded) {
              
              let dayChanged = this.isDiffDay(val,oldVal)
              
              if(dayChanged){
                //this.filters.preset = 'custom'
                //this.$store.commit('set', ['filters.preset','custom' ])
              }
              //this.openedFromDate = false
            }
            
         
        // this.onFromDatepickerClosed()
      }
      
    },
    'filters.toDate': {
      //this.fireFilters()
       handler(val,oldVal) {


            if(this.dateLoaded) {
              let dayChanged = this.isDiffDay(val,oldVal)
              
             
              if(dayChanged){
                //this.filters.preset = 'custom'
                //this.$store.commit('set', ['filters.preset','custom' ])
              }
              //this.openedToDate = false
            }
            
         
        // this.onFromDatepickerClosed()
      }
      
    },
    'filters.perPage': {
      handler(val,oldVal){
      }
    },
    'filters.page': {
      async handler(val,oldVal){
        if(val != oldVal) {
          
        }
      }
    },
    'filters.preset': {

      handler(val,oldVal){
        if(val != 'custom') {
          this.triggeredByExternal = true;
          this.preset(val)
        }
        
      }
    },
    'filters.searchById': {
      async handler(val,oldVal) {
        if(!val) {
          this.triggeredByExternal = true;
          this.isBusy = true
          await this.fireQuery()
          this.isBusy = false;
        }
      }
    },
    'filters.companyProfile': {
      async handler(val,oldVal){
        this.$store.commit('setDateFilter', ['page',1])
        this.triggeredByExternal = true;
        //this.preset(this.filters.preset)
        this.isBusy = true
        await this.fireQuery()
        this.isBusy = false;
      }
    },
    'driverAssignment.direct': {
      handler(val,oldVal){
        this.driverAssignment.newOffer = !val
      }
    },
    'driverAssignment.newOffer': {
      handler(val,oldVal){
        this.driverAssignment.direct = !val
      }
    },
    'map.item': function() {
      // if(!this.map.item.pu) {
      //   this.map.origin = null
      // } else {
      //    this.map.origin = { query: this.map.item.pu.coordinates.lat + ',' + this.map.item.pu.coordinates.lng };
      // }

      // if(!this.map.item.do) {
      //   this.map.destination = null
      // } else {
      //    this.map.destination = { query: this.map.item.do.coordinates.lat + ',' + this.map.item.do.coordinates.lng };
      // }
    }
    
  },
  methods: {
    tlcChanged(val) {
        console.log(val)
        val ? this.runTlcCheck() : false
    },
    dmvChanged(val) {
        console.log(val)
        val ? this.runDmvCheck() : false
    },
    directTlcChanged(val) {
        console.log(val)
        val ? this.runDirectTlcCheck() : false
    },
    directDmvChanged(val) {
        console.log(val)
        val ? this.runDirectDmvCheck() : false
    },
    async runTlcCheck() {
        this.driverAssignment.tlcBusy = true
        for (let j = 0; j < this.driverAssignment.filteredLinkedDrivers.length; j++) {
            if(this.driverAssignment.filteredLinkedDrivers[j].role == 'driver' && this.driverAssignment.filteredLinkedDrivers[j].tlc) {
                if(this.driverAssignment.filteredLinkedDrivers[j].tlc.number && this.driverAssignment.filteredLinkedDrivers[j].tlc.number != "") {
                    let response = await this.getTLCStatus(this.driverAssignment.filteredLinkedDrivers[j].tlc.number)
                    if(response.data.length > 0) {
                        this.$nextTick(()=> {

                            this.driverAssignment.filteredLinkedDrivers[j].tlc.check = response.data[0]
                        })
                    }
                }
            } 
            
        }
        this.driverAssignment.tlcBusy = false
    },
    async runDmvCheck() {
        //this.isBusy = true
        //this.$nextTick(async ()=> {
        //this.driverAssignment.dmvBusy = true
        this.driverAssignment.dmvBusy = true
        for (let j = 0; j < this.driverAssignment.filteredLinkedDrivers.length; j++) {
            if(this.driverAssignment.filteredLinkedDrivers[j].role == 'driver' ) {

                for (let n = 0; n < this.driverAssignment.filteredLinkedDrivers[j].fleet.length; n++) {
                    console.log(this.driverAssignment.filteredLinkedDrivers[j].fleet[n])
                    if(this.driverAssignment.filteredLinkedDrivers[j].fleet[n].mainInfo.plate && this.driverAssignment.filteredLinkedDrivers[j].fleet[n].mainInfo.plate != '') {
                        
                        let response = await this.getDMVStatus(this.driverAssignment.filteredLinkedDrivers[j].fleet[n].mainInfo.plate)
                        if(response.data.length > 0) {
                            //this.$nextTick(()=> {
    
                                this.driverAssignment.filteredLinkedDrivers[j].fleet[n].dmvCheck = response.data[0]
                            //})
                        }
                    }
                    
                    
                }
            } 
        }
        this.driverAssignment.dmvBusy = false
        //this.driverAssignment.dmvBusy = false
        // })
        this.isBusy = true;
        this.isBusy = false;
        
    },
    async runDirectTlcCheck() {
        this.directAssignment.tlcBusy = true
        for (let j = 0; j < this.directAssignment.filteredLinkedDrivers.length; j++) {
            if(this.directAssignment.filteredLinkedDrivers[j].role == 'driver' && this.directAssignment.filteredLinkedDrivers[j].tlc) {
                if(this.directAssignment.filteredLinkedDrivers[j].tlc.number && this.directAssignment.filteredLinkedDrivers[j].tlc.number != "") {
                    let response = await this.getTLCStatus(this.directAssignment.filteredLinkedDrivers[j].tlc.number)
                    if(response.data.length > 0) {
                        this.$nextTick(()=> {

                            this.directAssignment.filteredLinkedDrivers[j].tlc.check = response.data[0]
                        })
                    }
                }
            } 
            
        }
        this.directAssignment.tlcBusy = false
        this.directAssignment.tlcOn = true
    },
    async runDirectDmvCheck() {
        //this.isBusy = true
        //this.$nextTick(async ()=> {
          //this.directAssignment.dmvBusy = true
          this.directAssignment.dmvBusy = true
          for (let j = 0; j < this.directAssignment.filteredLinkedDrivers.length; j++) {
              if(this.directAssignment.filteredLinkedDrivers[j].role == 'driver' ) {

                  for (let n = 0; n < this.directAssignment.filteredLinkedDrivers[j].fleet.length; n++) {
                      console.log(this.directAssignment.filteredLinkedDrivers[j].fleet[n])
                      if(this.directAssignment.filteredLinkedDrivers[j].fleet[n].mainInfo.plate && this.directAssignment.filteredLinkedDrivers[j].fleet[n].mainInfo.plate != '') {
                          
                          let response = await this.getDMVStatus(this.directAssignment.filteredLinkedDrivers[j].fleet[n].mainInfo.plate)
                          if(response.data.length > 0) {
                              //this.$nextTick(()=> {
      
                                  this.directAssignment.filteredLinkedDrivers[j].fleet[n].dmvCheck = response.data[0]
                              //})
                          }
                      }
                      
                      
                  }
              } 
          }
          this.directAssignment.dmvBusy = false
          //this.directAssignment.dmvBusy = false
        // })
        this.isBusy = true;
        this.isBusy = false;
        
    },
    getTLCStatus(tlcNumber) {
        console.log('Get TLC Status')
        let params = {
            

        }
        //tlcNumber = '4353'
        return new Promise((resolve, reject) => {
            newAxios.create({
                headers: {},                    
            //}).get('https://data.cityofnewyork.us/resource/5tub-eh45.json?$query=SELECT%0A%20%20%60license_number%60%2C%0A%20%20%60name%60%2C%0A%20%20%60status_code%60%2C%0A%20%20%60status_description%60%2C%0A%20%20%60expiration_date%60%2C%0A%20%20%60last_update_date%60%2C%0A%20%20%60last_update_time%60%0AWHERE%20%60license_number%60%20%3D%20%22' + tlcNumber + '%22',
            }).get('https://data.cityofnewyork.us/resource/xjfq-wh2d.json?license_number=' + tlcNumber ,
            {params: params})
            .then(response =>{
                console.log(response)
                resolve(response)
            }).catch(e => {
                console.log(e);
                reject()
            })
        })
    },
    
    getDMVStatus(plateNumber) {
        console.log('Get DMV Status')
        console.log(plateNumber)
        let plateToCheck = plateNumber.toUpperCase()
        let params = {
            

        }
        //tlcNumber = '4353'
        return new Promise((resolve, reject) => {
          newAxios.create({
                headers: {},                    
            //}).get('https://data.cityofnewyork.us/resource/5tub-eh45.json?$query=SELECT%0A%20%20%60license_number%60%2C%0A%20%20%60name%60%2C%0A%20%20%60status_code%60%2C%0A%20%20%60status_description%60%2C%0A%20%20%60expiration_date%60%2C%0A%20%20%60last_update_date%60%2C%0A%20%20%60last_update_time%60%0AWHERE%20%60license_number%60%20%3D%20%22' + tlcNumber + '%22',
            }).get('https://data.cityofnewyork.us/resource/8wbx-tsch.json?dmv_license_plate_number=' + plateToCheck ,
            {params: params})
            .then(response =>{
                console.log('Get DMV status' , response)
                resolve(response)
            }).catch(e => {
                console.log(e);
                reject()
            })
        })
    },
    onSelectedVehicle(value) {
            
        console.log(value)
        let vehicleIndex = value.split('-')[0]
        let linkedUserDocId = value.split('-')[1]
        let userIndex = value.split('-')[2]

        this.$v.$touch()
        // for (let i = 0; i < selected.length; i++) {
        //     if(selected[i].requestedVehicle) {

        //         selected[i].requestedVehicle = selected[i].fleet[selected[i].requestedVehicle]
        //     }
            
        // }
    },
    checkVehicleRequired(item) {
        let val
        //this.$nextTick(function() {

            console.log(item)
            console.log(item.index + ': '+ item.selected &&
            item.role == 'driver' &&
            item.fleet.length > 0 && 
            !item.requestedVehicle)


            return item.selected &&
            item.role == 'driver' &&
            item.fleet.length > 0 && 
            !item.requestedVehicle ? true : false
        //})
    },

    statusClass(className) {
      let slug
      if(className) {
        slug = className.toLowerCase().replace(" ","-")
      }
      return slug
    },
    fromPickerInput(value) {
      
    },
    toPickerInput(value) {
     
    },
    onOfferSelected(items) {
      this.driverAssignment.selectedOffers = items
    },
    onDirectOfferSelected(items) {
      this.directAssignment.selectedOffers = items
    },
    openAuxAssignModal(res){
      this.driverAssignment.auxModal = true
      this.driverAssignment.actualRes = res
    },
    assignDriver() {
     
      let data = { 
        offerToAssign: this.driverAssignment.selectedOffers[0],
        userReservationTenantUid: this.user.data.uid,
        reservationId:  this.driverAssignment.actualReservationId

      }

      this.driverAssignment.busy = true
      let th = this;

      axios.patch('reservations/offers/assign-driver', data).then(response =>{
          for (let i = 0; i < th.reservations.mdData.length; i++) {
            if(th.reservations.mdData[i].readableId === response.data.reservation.readableId) {
              th.reservations.mdData[i] = response.data.reservation
            }
            
          }
          this.driverAssignment.busy = false
          th.snackMessage = 'Driver successfully pre assigned';
          th.showSnackbar = true;
          th.resetDriverAssignment()
      }).catch(e => {
          console.log(e);
          this.driverAssignment.busy = false
          th.resetDriverAssignment()
      })

      


      
    },
    async getResOffers(id, mdDataIndex) {
      let th = this ;
      let uid ;
      

      uid = this.user.data.uid

      var reservationsRef = this.$firebase.firestore().collection('users').doc(uid).collection('reservations').doc(id).collection('offers')
      let dataToSave = this.reservations.mdData[mdDataIndex]
      return new Promise((resolve, reject) => { 
        reservationsRef.onSnapshot(
          (querySnapshot) => {


              let offers = []
              querySnapshot.forEach(async (doc) => {
                  let offerDataToSave = doc.data();
                  offerDataToSave.id = doc.id
                  offers.push(offerDataToSave);
              })


              
              dataToSave.offers = offers ;
              let acceptedOffers = 0
              let preAssignedOffers = 0
              for (let k = 0; k < dataToSave.offers.length; k++) {
                if(this.statuses[dataToSave.offers[k].status] == 'Accepted') {
                  acceptedOffers++
                }

                if(this.statuses[dataToSave.offers[k].status] == 'Pre assigned') {
                  preAssignedOffers++
                }

                if(this.statuses[dataToSave.offers[k].status] == 'Assigned') {
                  
                  if(dataToSave.offers[k].timeline) {

                    for (let n = 0; n < dataToSave.offers[k].timeline.length; n++) {
                      
                      if(this.statuses[dataToSave.offers[k].timeline[n].status] == 'Assigned' && n === dataToSave.offers[k].timeline.length - 1 ) {
                          dataToSave.assignedDriver = dataToSave.offers[k].timeline[n].userInCharge
                      }
                    }
                  }
                     
                }
              }

              if(acceptedOffers > 0) {
                  this.driverAssignment.offerFilters.push('Accepted')
              }

              if(preAssignedOffers > 0) {
                this.driverAssignment.offerFilters.push('Pre assigned')
              }

              if(acceptedOffers == 0 && offers.length > 0) {
                this.driverAssignment.offerFilters.push('Offered')
              }

              dataToSave.acceptedOffers = acceptedOffers
              dataToSave.offersBusy = false
              this.reservations.mdData[mdDataIndex] = dataToSave
              this.$nextTick(function(){
                this.updateTableHeight()
                this.updateTableFixedColumnsData()
              })

              resolve(offers)
          }
          , (error) => { 
            console.log(error)
            reject(error)
          })
      })

      

    },
    async startDirectAssignment(res, index) {
      this.directAssignment.actualReservationId = res.id
      this.directAssignment.actualRes = res

      let linkedAvailableUsersToOffer = []
      let companyDrivers = []
      let th = this

      linkedAvailableUsersToOffer = this.directAssignment.linkedAvailableUsersToOffer.filter(async (driver) => {
       
        driver.requestedVehicle = null
        driver.index = index
        return driver
      })


      companyDrivers = this.directAssignment.companyDrivers.filter((driver) => {  
        
          return driver
      })

      
      this.directAssignment.filteredLinkedDrivers = [].concat(linkedAvailableUsersToOffer, companyDrivers);
      

      this.directAssignment.active = true

    },
    async startDriverAssignment(res, index) {

      let offers = await this.getResOffers(res.id, index)
      this.driverAssignment.actualReservationId = res.id
      this.driverAssignment.actualRes = res
      this.driverAssignment.actualRes.offers = offers
      let th = this

      let linkedAvailableUsersToOffer = []
      let companyDrivers = []

      linkedAvailableUsersToOffer = this.driverAssignment.linkedAvailableUsersToOffer.filter(async (driver) => {
        let matches = [];
        for (let i = 0; i < th.driverAssignment.actualRes.offers.length; i++) {
          
          th.driverAssignment.actualRes.offers[i].code != driver.code ? matches.push(false) : matches.push(true)
        }
        
        if(matches.includes(true)) {
         
        } else {
          
          driver.requestedVehicle = null
          driver.index = index
          return driver
        }
      })

      // companyDrivers = this.driverAssignment.companyDrivers.filter((driver) => {
      //   let matches = [];
      //   for (let i = 0; i < th.driverAssignment.actualRes.offers.length; i++) {
          

      //     th.driverAssignment.actualRes.offers[i].code != driver.profiles[0].code ? matches.push(false) : matches.push(true)
      //   }
      //   if(matches.includes(true)) {
         
      //   } else {
      //     let result =  driver.profiles[0]
      //     return result
      //   }
      // })

      let filteredInfoCompanyDrivers = []
      // for (let i = 0; i < companyDrivers.length; i++) {
      //   filteredInfoCompanyDrivers.push(companyDrivers[i].profiles[0])
        
      // }
      this.driverAssignment.filteredLinkedDrivers = [].concat(linkedAvailableUsersToOffer, filteredInfoCompanyDrivers);
      

      this.driverAssignment.active = true
    },
    deleteAssignation() {
      let data = { 
        offers: this.directAssignment.selectedOffers ,
        userReservationTenantUid: this.user.data.uid,
        reservationId:  this.directAssignment.actualReservationId

      }

      this.directAssignment.busy = true
      let th = this;
      axios.patch('reservations/offers/delete', data).then(response =>{
          for (let i = 0; i < th.reservations.mdData.length; i++) {
            if(th.reservations.mdData[i].readableId === response.data.reservation.readableId) {
              th.reservations.mdData[i] = response.data.reservation
            }
            
          }
          this.directAssignment.busy = false
          th.snackMessage = 'Offer successfully deleted';
          th.showSnackbar = true;
          th.resetDirectAssignment()
      }).catch(e => {
          console.log(e);
          this.driverAssignment.busy = false
          th.resetDirectAssignment()
      })
    },
    deleteOffers() {
      let data = { 
        offers: this.driverAssignment.selectedOffers ,
        userReservationTenantUid: this.user.data.uid,
        reservationId:  this.driverAssignment.actualReservationId

      }

      this.driverAssignment.busy = true
      let th = this;
      axios.patch('reservations/offers/delete', data).then(response =>{
          for (let i = 0; i < th.reservations.mdData.length; i++) {
            if(th.reservations.mdData[i].readableId === response.data.reservation.readableId) {
              th.reservations.mdData[i] = response.data.reservation
            }
            
          }
          this.driverAssignment.busy = false
          th.snackMessage = 'Offer successfully deleted';
          th.showSnackbar = true;
          th.resetDriverAssignment()
      }).catch(e => {
          console.log(e);
          this.driverAssignment.busy = false
          th.resetDriverAssignment()
      })

    },
    sendDirectAssigment() {
      if(this.validateDirectOfferSend()) {
        console.log('send direct assignment')
        
        let data = { 
          usersToOffer: [this.directAssignment.selectedDriver] ,
          userReservationTenantUid: this.user.data.uid,
          reservationId:  this.directAssignment.actualReservationId,
          driverAmount: this.directAssignment.actualRes.driverAmount,
          offerComment: this.directAssignment.actualRes.offerComment

        }

        this.directAssignment.busy = true
        let th = this;
        axios.patch('reservations/direct-assignation/send', data).then(response =>{
            for (let i = 0; i < th.reservations.mdData.length; i++) {
              if(th.reservations.mdData[i].readableId === response.data.reservation.readableId) {
                th.reservations.mdData[i] = response.data.reservation
              }
              
            }
            this.directAssignment.busy = false
            th.snackMessage = 'Direct assignation sent successfully';
            th.showSnackbar = true;
            th.resetDirectAssignment()
        }).catch(e => {
            console.log(e);
            this.driverAssignment.busy = false
            th.resetDirectAssignment()
        })
        
      } else {
        console.log('not valid send direct assignment')
        this.$v.$touch()
      }
    },
    validateDirectOfferSend() {
        let driverAmount;
        let invalidVehicles = []
        driverAmount = this.$v.directAssignment.actualRes.driverAmount.$invalid;
        console.log('driverAmount', driverAmount)
        //this.$nextTick(function(){

            for (let j = 0; j < this.directAssignment.filteredLinkedDrivers.length; j++) {
                if(
                    this.directAssignment.filteredLinkedDrivers[j].selected &&
                    this.directAssignment.filteredLinkedDrivers[j].role == 'driver' &&
                    this.directAssignment.filteredLinkedDrivers[j].fleet.length > 0 && 
                    !this.directAssignment.filteredLinkedDrivers[j].requestedVehicle

                ) {
                    invalidVehicles.push(true)
                    //requestedVehicle ? vehicles.push(true) : vehicles.push(false)
                }
            }
            return invalidVehicles.length == 0  ? true : false ;
            //return !driverAmount && invalidVehicles.length == 0  ? true : false ;
        //})

    },
    validateOfferSend() {
      let driverAmount;
        
      driverAmount = this.$v.driverAssignment.actualRes.driverAmount.$invalid;

      
      return !driverAmount  ? true : false ;
    },
    sendOffer() {
      if(this.validateOfferSend()) {

        let data = { 
          usersToOffer: this.driverAssignment.selectedDrivers ,
          userReservationTenantUid: this.user.data.uid,
          reservationId:  this.driverAssignment.actualReservationId,
          driverAmount: this.driverAssignment.actualRes.driverAmount,
          offerComment: this.driverAssignment.actualRes.offerComment
  
        }
  
        this.driverAssignment.busy = true
        let th = this;
        axios.patch('reservations/offers/send', data).then(response =>{
            for (let i = 0; i < th.reservations.mdData.length; i++) {
              if(th.reservations.mdData[i].readableId === response.data.reservation.readableId) {
                th.reservations.mdData[i] = response.data.reservation
              }
              
            }
            this.driverAssignment.busy = false
            th.snackMessage = 'Offer sent successfully';
            th.showSnackbar = true;
            th.resetDriverAssignment()
        }).catch(e => {
            console.log(e);
            this.driverAssignment.busy = false
            th.resetDriverAssignment()
        })
      } else {
        this.$v.$touch()
      }

    },
    revokeDirectAssignation() {
      let data = { 
        driverToRevoke: this.directAssignment.selectedOffers[0] ,
        userReservationTenantUid: this.user.data.uid,
        reservationId:  this.directAssignment.actualReservationId,
        directAssignment: true
      }

      this.directAssignment.busy = true
      let th = this;
      axios.patch('reservations/assignation/revoke', data).then(response =>{
          for (let i = 0; i < th.reservations.mdData.length; i++) {
            if(th.reservations.mdData[i].readableId === response.data.reservation.readableId) {
              th.reservations.mdData[i] = response.data.reservation
            }
            
          }
          this.directAssignment.busy = false
          th.snackMessage = 'Assigment revoked successfully';
          th.showSnackbar = true;
          th.resetDirectAssignment()
      }).catch(e => {
          console.log(e);
          this.driverAssignment.busy = false
          th.resetDirectAssignment()
      })
    },
    revokeAssignation() {
      let data = { 
        driverToRevoke: this.driverAssignment.selectedOffers[0] ,
        userReservationTenantUid: this.user.data.uid,
        reservationId:  this.driverAssignment.actualReservationId

      }

      this.driverAssignment.busy = true
      let th = this;
      axios.patch('reservations/assignation/revoke', data).then(response =>{
          for (let i = 0; i < th.reservations.mdData.length; i++) {
            if(th.reservations.mdData[i].readableId === response.data.reservation.readableId) {
              th.reservations.mdData[i] = response.data.reservation
            }
            
          }
          this.driverAssignment.busy = false
          th.snackMessage = 'Assigment revoked successfully';
          th.showSnackbar = true;
          th.resetDriverAssignment()
      }).catch(e => {
          console.log(e);
          this.driverAssignment.busy = false
          th.resetDriverAssignment()
      })

    },
    sendCommunitiesOffers() {

      if(this.validateOfferSend()) {
        let data = {
          communitiesToOffer: this.driverAssignment.selectedCommunities ,
          userReservationTenantUid: this.user.data.uid,
          reservationId:  this.driverAssignment.actualReservationId,
          driverAmount: this.driverAssignment.actualRes.driverAmount,
          offerComment: this.driverAssignment.actualRes.offerComment
        }
  
        this.driverAssignment.busy = true
        let th = this;
        axios.patch('reservations/offers/communities/send', data).then(response =>{
            for (let i = 0; i < th.reservations.mdData.length; i++) {
              if(th.reservations.mdData[i].readableId === response.data.reservation.readableId) {
                th.reservations.mdData[i] = response.data.reservation
              }
              
            }
            this.driverAssignment.busy = false
            th.snackMessage = 'Offers sent successfully';
            th.showSnackbar = true;
            th.resetDriverAssignment()
        }).catch(e => {
            console.log(e);
            this.driverAssignment.busy = false
            th.resetDriverAssignment()
        })
      } else {
        this.$v.$touch()
      }
    },
    resetDriverAssignment() {
        this.driverAssignment.busy =  false,
        this.driverAssignment.active =  false,
        this.driverAssignment.actualRes =  null,
        this.driverAssignment.direct =  false,
        this.driverAssignment.newOffer =  true,
        this.driverAssignment.selectedDriver =  null,
        this.driverAssignment.selectedDriverText =  null,
        this.driverAssignment.selectedCommunity =  null,
        this.driverAssignment.queryResults =  [],
        this.driverAssignment.zoneInputFocused =  false,
        this.driverAssignment.vehicleType =  {
          id : null
        }
        this.driverAssignment.searchTerm =  null,
        this.driverAssignment.searching =  false,
        this.driverAssignment.actualReservationId =  null
    },
    resetDirectAssignment() {
        this.directAssignment.busy =  false,
        this.directAssignment.active =  false,
        this.directAssignment.actualRes =  null,
        this.directAssignment.selectedDriver =  null,
        this.directAssignment.selectedDriverText =  null,
       
        this.directAssignment.actualReservationId =  null
    },
    getValidationClass (fieldName) {
        let field = null;

        
        if(fieldName === 'vehicleType') {
          field = this.$v.driverAssignment.vehicleType.id;
        } else if(fieldName === 'fencesList') {
          field = this.$v.coveredArea.fencesList;

        } else if (fieldName === 'driverAmount') {
          field = this.$v.driverAssignment.actualRes.driverAmount;
        } else {

            field = this.$v[fieldName];
        }
        
        
        
        if (field) {
            //return true
            return field.$invalid && field.$dirty
        }
    },
    validateCustomSearch(){
        let vehicleType, fencesList;
        
        vehicleType = this.$v.driverAssignment.vehicleType.id.$invalid;
        fencesList = this.$v.coveredArea.fencesList.$invalid;

        
        return !vehicleType && !fencesList ? true : false ;


    },
    performQuery() {
      if(this.validateCustomSearch()) {
        this.locateCursor()
        let data = {
            requiredFence: this.coveredArea.fencesList[0],
            requiredPaths: [],
            roleToGet: 'driver',
            subroleToGet: 'owner-operator',
            vehicleTypeBase: this.driverAssignment.vehicleType.id
        }
        
        for (let i = 0; i < this.coveredArea.fencesList[0].indexes.length; i++) {
            data.requiredPaths.push(this.coveredArea.paths[this.coveredArea.fencesList[0].indexes[i]])
            
        }

        this.zonePreview.searched = {
            geometry: {
                lat: data.requiredFence.geometry.lat,
                lng: data.requiredFence.geometry.lng
            },
            paths: data.requiredPaths,
            show: true,
            formatted_address: data.requiredFence.formatted_address
        }

        let th = this
        //th.coveredArea.fencesList = []
        //th.coveredArea.fencesList[0].indexes = []
        //this.coveredArea.paths = []
        this.driverAssignment.searching = true;
        axios.patch('users/search-by-zone', data).then(response =>{
            th.activeQueryListType = 'zone'
            let results = response.data.users
            
            th.driverAssignment.queryResults = results ;
            th.driverAssignment.searching = false;
        }).catch(e => {
            console.log(e);
            th.driverAssignment.searching = false;
        })


              

          
          
      } else {
          this.$v.$touch()
      }
    },
    resetCursor(){
        if(this.$refs.autocomplete.$el.value == '') {
            this.driverAssignment.zoneInputFocused = false
        }
        
    
    },
    async getBoundarie(lat, lon) {
        let params = {
            apiKey: '3f5d675290ce41e08917bc9f40516382',
            geometry: 'geometry_10000',
            lat: lat,
            lon: lon

        }
        return new Promise((resolve, reject) => {
            newAxios.create({
                headers: {},                    
            }).get('https://api.geoapify.com/v1/boundaries/part-of',
            {params: params})
            .then(response =>{
                resolve(response.data)
            }).catch(e => {
                console.log(e);
                reject()
            })
        })
    },
    async setFence(place) {
        this.queryResults = []
        this.coveredArea.fencesList = []
        let newFence = {
            formatted_address:place.formatted_address,
            indexes: [],
            address_components: place.address_components,
            geometry: {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
            }
        }

        this.geofence.center = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
        };
        
        let placeLevel = place.address_components[0].types[0]

        newFence.name = place.formatted_address
        // Google naming 

        // country
        // administrative_area_level_1
        // locality
        // sublocality_level_1

        let boundarie = await this.getBoundarie(this.geofence.center.lat, this.geofence.center.lng)
        this.coveredArea.coordinates.lat = this.geofence.center.lat
        this.coveredArea.coordinates.lng = this.geofence.center.lng
        this.coveredArea.zoom = 10
        let categoryToLook = null;
        let alternativeCategoryToLook = null;
        let secondAlternativeCategoryToLook = null;
        switch(placeLevel) {
            case 'country':
                //categoryToLook = "administrative.country_part_level"
                categoryToLook = "administrative.country_level"
                break;
            case 'administrative_area_level_1':
                //categoryToLook = "administrative.state_level"
                //categoryToLook = "administrative.county_level"
                categoryToLook = "administrative.country_part_level"
                break;
            case 'administrative_area_level_2':
                //categoryToLook = "administrative.state_level"
                categoryToLook = "administrative.county_level"
                //categoryToLook = "administrative.district_level"
                break;
            case 'locality':
                //categoryToLook = "administrative.county_level"
                //categoryToLook = "administrative.city_level"
                categoryToLook = "administrative.state_level"
                alternativeCategoryToLook = "administrative.district_level"
                // ithaca, newy york : syracuse ny
                secondAlternativeCategoryToLook = "administrative.city_level"
                    // ex New York , New York
                // ex New York , New York
                break;
            case 'sublocality_level_1':
                categoryToLook = "administrative.city_level"
                // ex. Manhattan, Bronx, Queens
                break;
            case 'neighborhood':
                //categoryToLook = "administrative.city_level"
                this.areaValidation.message = 'Search a place larger than a neighborhood'
                break;
            
        }
        let finalFeature = null
        if(categoryToLook || alternativeCategoryToLook || secondAlternativeCategoryToLook) {
            for (let i = 0; i < boundarie.features.length; i++) {
                if(
                    boundarie.features[i].properties.categories[1] === categoryToLook
                    || boundarie.features[i].properties.categories[1] === alternativeCategoryToLook
                    || boundarie.features[i].properties.categories[1] === secondAlternativeCategoryToLook
                    ) 
                {
                    finalFeature = boundarie.features[i]
                }
            }

        } else {
            this.areaValidation.valid = false;

        }

        if(finalFeature) {
            if(finalFeature.geometry.type === "Polygon") {
                let pathToSave = []

                for (let i = 0; i < finalFeature.geometry.coordinates[0].length; i++) {
                    pathToSave.push({
                        lng: finalFeature.geometry.coordinates[0][i][0],
                        lat: finalFeature.geometry.coordinates[0][i][1],
                    })
                    
                    
                }
                let newIndex = this.coveredArea.paths.push(pathToSave) - 1
                newFence.indexes.push(newIndex)
            } else if(finalFeature.geometry.type === "MultiPolygon") {
                for (let i = 0; i < finalFeature.geometry.coordinates.length; i++) {
                    let pathToSave = []
                    for (let j = 0; j < finalFeature.geometry.coordinates[i][0].length; j++) {
                        pathToSave.push({
                            lng: finalFeature.geometry.coordinates[i][0][j][0],
                            lat: finalFeature.geometry.coordinates[i][0][j][1],
                        })
                        
                        
                    }
                    let newIndex =  this.coveredArea.paths.push(pathToSave) - 1
                    newFence.indexes.push(newIndex)
                    
                }
            }
            this.coveredArea.fencesList.push(newFence)
        }

        
        

        
    },
    locateCursor(){
        this.$refs.autocomplete.$el.focus()
        this.driverAssignment.zoneInputFocused = true
    },
    onSelectAllDriverList(selected) {
      //this.driverAssignment.selectedDrivers = selected ;

      this.driverAssignment.selectedDrivers = selected ;
      let activeIds = []

      for (let j = 0; j < selected.length; j++) {
          activeIds.push(selected[j].id) 
      }
      console.log(activeIds)

      for (let i = 0; i < this.driverAssignment.filteredLinkedDrivers.length; i++) {
          if(activeIds.includes(this.driverAssignment.filteredLinkedDrivers[i].id) ) {
              this.driverAssignment.filteredLinkedDrivers[i].selected = true
              if(this.driverAssignment.filteredLinkedDrivers[i].fleet.length == 1) {
                  this.driverAssignment.filteredLinkedDrivers[i].requestedVehicle = 0 + '-' + this.driverAssignment.filteredLinkedDrivers[i].id + '-' + this.driverAssignment.filteredLinkedDrivers[i].index
              }
          } else {
              this.driverAssignment.filteredLinkedDrivers[i].selected = false 
          }
          activeIds.includes(this.driverAssignment.filteredLinkedDrivers[i].id) ? 
                              this.driverAssignment.filteredLinkedDrivers[i].selected = true : 
                              this.driverAssignment.filteredLinkedDrivers[i].selected = false 
      }

    },
    onSelectDirectAssignationList(selected) {
      console.log(selected)
      this.directAssignment.selectedDriver = selected[0] ;

      let activeIds = []

      
      for (let j = 0; j < selected.length; j++) {
          activeIds.push(selected[j].id) 
      }
      
      console.log(activeIds)

      for (let i = 0; i < this.directAssignment.filteredLinkedDrivers.length; i++) {
          if(activeIds.includes(this.directAssignment.filteredLinkedDrivers[i].id) ) {
              this.directAssignment.filteredLinkedDrivers[i].selected = true
              this.directAssignment.filteredLinkedDrivers[i].disabled = false
              if(this.directAssignment.filteredLinkedDrivers[i].fleet.length == 1) {
                  this.directAssignment.filteredLinkedDrivers[i].requestedVehicle = 0 + '-' + this.directAssignment.filteredLinkedDrivers[i].id + '-' + this.directAssignment.filteredLinkedDrivers[i].index
              }
          } else {
              if(activeIds.length > 0) {
                  this.directAssignment.filteredLinkedDrivers[i].disabled = true 
              } else {
                  this.directAssignment.filteredLinkedDrivers[i].disabled = false 
              }
              this.directAssignment.filteredLinkedDrivers[i].selected = false 
          }
          // activeIds.includes(this.directAssignment.filteredLinkedDrivers[i].id) ? 
          //                     this.directAssignment.filteredLinkedDrivers[i].selected = true : 
          //                     this.directAssignment.filteredLinkedDrivers[i].selected = false 
      }

    },
    
    onSelectCommunity(selected) {
      this.driverAssignment.selectedCommunities = selected ;
    },
    onSelectCustomSearch(selected) {
      this.driverAssignment.selectedCustomSearch = selected ;
    },
    setDirectDriver(item) {
      this.driverAssignment.selectedDriver = item
      this.driverAssignment.selectedDriverText = item.email + ' (' +  item.code + ')'
    },
    directDriverAssignmentChanged(searchTerm) {
        // this.resetFlightPack()
        // this.airlines = new Promise(resolve => {
        //     window.setTimeout(() => {
        //         if (!searchTerm) {
        //             let aux = this.airlinesList.slice(0, 10);
        //             resolve(aux);
        //             //resolve(this.airlinesList)
        //         } else {
        //             const term = searchTerm.toLowerCase()
        //             resolve(this.airlinesList.filter(({ name }) => name.toLowerCase().includes(term)).slice(0, 10))
                        
        //         }
        //     }, 200)
        // })
        
    },
    focusedDriverDirectAssignment(ev){
        //this.resetFlightPack()
        
        ev.target.value = ""
        this.driverAssignment.selectedDriver = null
        this.driverAssignment.selectedDriverText = null
       
    },
    fireCheck() {
      return new Promise((resolve, reject) => {
         axios.put('check-flight-status-globally').then(response =>{
              resolve()
          }).catch(e => {
              console.log(e);
              
              reject()
          })
      }) 
    },
    closeMapDialog() {
      this.map.active = false;
      this.map = {
        title: null,
        active: false,
        item: null,
        
      }

    },
    openMapDialog(item) {
      
      let th = this
      this.map.active = true
      
      this.$nextTick(() => {
        if(this.$refs.map) {
          this.$refs.map.$mapPromise.then((map) => {
            th.map.item = item
          })
        }
        
      })
      // this.$refs.map.$mapPromise.then((map) => {
      //   th.map.item = item
      // })
      //setTimeout(function(){
        
      //},3000)
      

      // this.$nextTick(() => {
        
      //   this.map.origin = { query: item.pu.coordinates.lat + ',' + item.pu.coordinates.lng }
      //   this.map.destination = { query: item.do.coordinates.lat + ',' + item.do.coordinates.lng }

      //   if (item.waypoints.length == 0 )  {
      //     this.map.waypoints = null
      //   } else {

      //     const total = [];
      //     item.waypoints.forEach((waypoint,index) => {
      //         if(!waypoint.stop){
      //             //total.push(null);
      //         } else {
      //             total.push({
      //                 stopover:true,
      //                 location: !waypoint.stop ? null : waypoint.stop.location
      //             })

                  
      //         }
              
      //     });
      //   this.map.waypoints = total;

      //   }
        
      //   this.map.title = 'Trip route' 
      // })
      
    },
    checkPaidReservations() {
        let deleteAvailable = []
        this.bulk.deniedIds = []
        for (let i = 0; i < this.alternateToolBar.items.length; i++) {
            
            let statusName = this.getCompleteStatus(this.alternateToolBar.items[i].paymentStatus).name
            if(statusName == 'Paid' || statusName == 'Partially paid'){
                deleteAvailable.push(false)
                this.bulk.deniedIds.push(this.alternateToolBar.items[i].readableId)
            } else {
               deleteAvailable.push(true)
            }
          
        }
        
        return !deleteAvailable.includes(false)
    },
    async fireBulk() {
      this.bulk.busy = true;
      let th = this
      
      const batch = firebase.firestore().batch()

      for (let i = 0; i < this.alternateToolBar.items.length; i++) {
        let ref = firebase.firestore().collection('users').doc(this.user.data.uid).collection('reservations').doc(this.alternateToolBar.items[i].id)
        batch.delete(ref);
      }
      await batch.commit();
      th.bulk.busy = false;
      th.alternateToolBar = {
        active: false,
        count: 0,
        items: [],
      }
      th.snackMessage = 'Bulk action successful';
      th.showSnackbar = true;
      th.resetBulk()
    },
    checkLock() {
      this.bulk.unlockCode === this.bulk.unlockTry ? this.bulk.unlocked = true : this.bulk.unlocked = false
    },
    resetBulk(){
      this.bulk = {
        title: null,
        available: false,
        active:false,
        error: {
          title: null,
          message: null
        },
        confirmed: false,
        action: null,
        deniedIds: [],
        unlockCode: null,
        unlockTry: null,
        unlocked: false,
        busy: false

      }
    },
    doBulk(action) {
      this.resetBulk()
      let deleteAvailable = this.checkPaidReservations();
      this.bulk.available = deleteAvailable ;
      this.bulk.title = 'Bulk ' + action
      this.bulk.action = action
      switch(action) {
        case 'delete':
          if(!this.bulk.available){
            let text = this.bulk.deniedIds.length == 1 ? 'its' : 'their'
            this.bulk.error.message = 'The following IDs can not be deleted due to ' + text  + ' payment status'
          }
          break;
      }

      if(this.bulk.available) {
        this.bulk.unlockCode = moment().tz(this.timezone).format('HHmm')
      }
      this.bulk.active = true
      

    },
    checkboxSelected(item){
      
      let showToolbar = false ;
      let count = 0;
      let selectedItems = [];
      for (let i = 0; i < this.filteredReservationsList.length; i++) {
       
        if(this.filteredReservationsList[i].checked) {
          showToolbar = true
          count++
          selectedItems.push(this.filteredReservationsList[i]);
        }
        
      }
       
      this.alternateToolBar.active = showToolbar
      this.alternateToolBar.count = count
      this.alternateToolBar.items = selectedItems
     
    },
    onFromFocusIn(e) {
      //this.openedFromDate = true
      // var fromPicker = document.querySelectorAll('.md-datepicker-date-select')[0]
      
      // fromPicker.addEventListener("click", function() {
      // }, false);
      // setTimeout(function() {
      //   fromPicker.click(); 
      // }, 1000);

    },
    onFromFocusOut(e) {
     
    },
    onToFocusIn(e) {
     
      //this.openedToDate = true
    },
    onToFocusOut(e) {
    
      
    },
    onFromDatepickerClosed(){
      // if(!this.isBusy) {
      //   this.$refs.toDatepicker.showDialog = true
      // } 
      
    },
    async searchId(){
       this.isBusy = true
        let th = this ;
        let data = {
           user: this.user.data.uid,
           id: this.filters.goToID
        }
        return new Promise((resolve, reject) => {
            axios.get('reservations/go-to-id', { params: data }).then(response =>{
              resolve()
            }).catch(e => {
                console.log(e);
                this.isBusy = false
                reject()
            })
        }) 
    },
    async preset(name){
      this.isBusy = true
      this.triggeredByExternal = true
      let timezone ;
      let companyProfile;
      if(this.filters.companyProfile) {
        if(this.companyProfiles.length == 0) {
           let zone_name =  moment.tz.guess();
           let browserZone = moment.tz(zone_name).zoneAbbr() 
           timezone = zone_name
           companyProfile = null
         } else {
           for (let i = 0; i < this.companyProfiles.length; i++) {
             if(this.companyProfiles[i].id  ===  this.filters.companyProfile) {
               timezone = this.companyProfiles[i].timeZone
               companyProfile = this.companyProfiles[i]
             }
             
           }
         }
      } else {
          let zone_name =  moment.tz.guess();
          let browserZone = moment.tz(zone_name).zoneAbbr() 
          timezone = zone_name
          companyProfile = null
      }

      let options = {
        user: this.user.data.uid,
        fromDate:null ,
        toDate:null,
        companyProfile: companyProfile,
        page:this.filters.page,
        pageSize:this.filters.perPage,
        sort:null,
        sortOrder:null,
        //rowsQty:10,
        search:this.filters.search,
      }

      let from ;
      let to;
      switch(name) {
        case 'today':
          from = moment().tz(timezone).set({hour:0,minute:0,second:0})
          to =  moment().tz(timezone).set({hour:23,minute:59,second:59})
          options.fromDate = from.unix()
          options.toDate = to.unix()
          break;
        case 'yesterday':
          from = moment().subtract(1,'day').tz(timezone).set({hour:0,minute:0,second:0})
          to =  moment().subtract(1,'day').tz(timezone).set({hour:23,minute:59,second:59})
          options.fromDate = from.unix()
          options.toDate = to.unix()
          break;
        case 'tomorrow':
          from = moment().add(1,'day').tz(timezone).set({hour:0,minute:0,second:0})
          to =  moment().add(1,'day').tz(timezone).set({hour:23,minute:59,second:59})
          options.fromDate = from.unix()
          options.toDate = to.unix()
          break;
        case 'this month':
          from = moment().tz(timezone).startOf('month').set({hour:0,minute:0,second:0})
          to  = moment().tz(timezone).endOf('month').set({hour:23,minute:59,second:59})
          options.fromDate = from.unix()
          options.toDate = to.unix()
          break
         case 'previous month':
          from = moment().add(-1, 'months').tz(timezone).startOf('month').set({hour:0,minute:0,second:0})
          to  = moment().add(-1, 'months').tz(timezone).endOf('month').set({hour:23,minute:59,second:59})
          options.fromDate = from.unix()
          options.toDate = to.unix()
          break
        case 'next month':
          from = moment().add(1, 'months').tz(timezone).startOf('month').set({hour:0,minute:0,second:0})
          to  = moment().add(1, 'months').tz(timezone).endOf('month').set({hour:23,minute:59,second:59})
          options.fromDate = from.unix()
          options.toDate = to.unix()
          break
        case 'this week':
          from = moment().tz(timezone).startOf('isoWeek').set({hour:0,minute:0,second:0})
          to  = moment().tz(timezone).endOf('isoWeek').set({hour:23,minute:59,second:59})
          options.fromDate = from.unix()
          options.toDate = to.unix()
          break
        case 'last week':
          from = moment().subtract(1, 'weeks').tz(timezone).startOf('isoWeek').set({hour:0,minute:0,second:0})
          to  = moment().subtract(1, 'weeks').tz(timezone).endOf('isoWeek').set({hour:23,minute:59,second:59})
          options.fromDate = from.unix()
          options.toDate = to.unix()
          break
          case 'next week':
          from = moment().add(1, 'weeks').tz(timezone).startOf('isoWeek').set({hour:0,minute:0,second:0})
          to  = moment().add(1, 'weeks').tz(timezone).endOf('isoWeek').set({hour:23,minute:59,second:59})
          options.fromDate = from.unix()
          options.toDate = to.unix()
          break

      }
      

      
      if(name != 'custom') {
        //this.options = options
        //this.updatePagination(1, this.filters.perPage)
        this.$store.commit('setDateFilter', ['page',1])
        await this.fireQuery(options)
      }


      this.isBusy = false

    },
    async fireQuery(options){
      this.mainToolbarContracted = true
      //  D M YYYY
      let fromYear = parseInt(moment(this.filters.fromDate).format('YYYY'))
      let fromMonth = parseInt(moment(this.filters.fromDate).format('M')) - 1 
      let fromDate = parseInt(moment(this.filters.fromDate).format('D'))
      let toYear = parseInt(moment(this.filters.toDate).format('YYYY'))
      let toMonth = parseInt(moment(this.filters.toDate).format('M')) - 1
      let toDate = parseInt(moment(this.filters.toDate).format('D'))

      //this.isBusy = true
      let th = this ;
      let timezone 
      let companyProfile;
      let zone_name =  moment.tz.guess();
      if(th.filters.companyProfile) {
        if(this.companyProfiles.length == 0) {
          
          let browserZone = moment.tz(zone_name).zoneName() 
          timezone = zone_name
          companyProfile = null
        } else {
          for (let i = 0; i < this.companyProfiles.length; i++) {
            if(this.companyProfiles[i].id  ===  th.filters.companyProfile) {
              console.log(this.companyProfiles[i]);
              timezone = this.companyProfiles[i].timeZone
              companyProfile = this.companyProfiles[i]
            }
            
          }
          if(!timezone) {
            timezone = zone_name
            companyProfile = null
          }
        }
      } else {
        timezone = zone_name
        companyProfile = null
      }

      console.log(timezone);
      console.log(th.filters.companyProfile);
      console.log(this.companyProfiles);
      this.$store.commit('setDateFilter', ['companyProfile',companyProfile ? companyProfile.id : 0])
      this.$store.commit('set', ['timezone',timezone ])
      this.$store.commit('set', ['utc',moment.tz(timezone).utcOffset() ])
      let data = {}
      let from ;
      let to;
      if(!options) {
        
        switch(this.filters.preset) {
          case 'today':
            from = moment().tz(timezone).set({hour:0,minute:0,second:0})
            to =  moment().tz(timezone).set({hour:23,minute:59,second:59})
           
            break;
          case 'yesterday':
            from = moment().subtract(1,'day').tz(timezone).set({hour:0,minute:0,second:0})
            to =  moment().subtract(1,'day').tz(timezone).set({hour:23,minute:59,second:59})
            
            break;
          case 'tomorrow':
            from = moment().add(1,'day').tz(timezone).set({hour:0,minute:0,second:0})
            to =  moment().add(1,'day').tz(timezone).set({hour:23,minute:59,second:59})
            
            break;
          case 'this month':
            from = moment().tz(timezone).startOf('month').set({hour:0,minute:0,second:0})
            to  = moment().tz(timezone).endOf('month').set({hour:23,minute:59,second:59})
            
            break
          case 'previous month':
            from = moment().add(-1, 'months').tz(timezone).startOf('month').set({hour:0,minute:0,second:0})
            to  = moment().add(-1, 'months').tz(timezone).endOf('month').set({hour:23,minute:59,second:59})
            
            break
          case 'next month':
            from = moment().add(1, 'months').tz(timezone).startOf('month').set({hour:0,minute:0,second:0})
            to  = moment().add(1, 'months').tz(timezone).endOf('month').set({hour:23,minute:59,second:59})
            
            break
          case 'this week':
            from = moment().tz(timezone).startOf('isoWeek').set({hour:0,minute:0,second:0})
            to  = moment().tz(timezone).endOf('isoWeek').set({hour:23,minute:59,second:59})
            
            break
          case 'last week':
            from = moment().subtract(1, 'weeks').tz(timezone).startOf('isoWeek').set({hour:0,minute:0,second:0})
            to  = moment().subtract(1, 'weeks').tz(timezone).endOf('isoWeek').set({hour:23,minute:59,second:59})
            
            break
            case 'next week':
            from = moment().add(1, 'weeks').tz(timezone).startOf('isoWeek').set({hour:0,minute:0,second:0})
            to  = moment().add(1, 'weeks').tz(timezone).endOf('isoWeek').set({hour:23,minute:59,second:59})
            
            break

        }
        
        
        let start = { year: fromYear , month: fromMonth , date: fromDate ,hour:0,minute:0,second:0 }
        let end = { year: toYear , month: toMonth, date: toDate ,hour:23,minute:59,second:49 }
        console.log('start',start)
        console.log('end',end)


        var fd = from ? from : moment().tz(timezone).set(start)
        var td = to ? to : moment().tz(timezone).set(end)
        // var fd = this.filters.fromDate ? moment().tz(timezone).set(start) : moment().tz(timezone).set({hour:0,minute:0,second:0});
        // var td = this.filters.toDate ? moment().tz(timezone).set(end) :  moment().tz(timezone).set({hour:23,minute:59,second:59});

        //var fd =  moment().tz(timezone).set({hour:0,minute:0,second:0});
        //var td =  moment().tz(timezone).set({hour:23,minute:59,second:59});
       


        console.log('fd',fd)
        console.log('td',td)
        if(!this.filters.fromDate) {
          //this.filters.fromDate = parseInt(fd.format('x'))
        }
        
        if(!this.filters.toDate) {
          //this.filters.toDate = parseInt(fd.format('x'))
        }
        
        //page, pageSize, sort, sortOrder
        data = {  
                user: this.user.data.uid,
                fromDate:fd.unix() ,
                toDate: td.unix(),
                companyProfile: companyProfile,
                page:this.filters.page,
                pageSize:this.filters.perPage,
                sort:this.filters.sort,
                sortOrder:this.filters.sortOrder,
                //rowsQty:10,
                search:this.filters.search,
                
              
        }

      } else {
        data = options
      }

      data.resOwnerUid = this.user.data.uid
      data.requesterRole = this.user.data.activeRole
      data.requesterEmail = this.user.data.email
      data.page = this.filters.page 
      data.last = this.filters.last
      data.first = this.filters.first
      data.direction = this.filters.direction
      if(this.filters.searchById) {
        data.searchById = this.filters.searchById
        data.goToID = parseInt(this.filters.goToID)
      }
      
      if(this.filters.page === 1) {

      } else {
        
      }

      // Parse for date picker

      let fakeFromOrigin = moment.unix(data.fromDate).tz(timezone)
      let fakeToOrigin = moment.unix(data.toDate).tz(timezone)

      let fakeDatepickerDate_fromYear = parseInt(fakeFromOrigin.format('YYYY'))
      let fakeDatepickerDate_fromMonth = parseInt(fakeFromOrigin.format('M')) - 1 
      let fakeDatepickerDate_fromDate = parseInt(fakeFromOrigin.format('D'))
      let fakeDatepickerDate_toYear = parseInt(fakeToOrigin.format('YYYY'))
      let fakeDatepickerDate_toMonth = parseInt(fakeToOrigin.format('M')) - 1
      let fakeDatepickerDate_toDate = parseInt(fakeToOrigin.format('D'))
      
      let fakeDatepickerDate_start = { year: fakeDatepickerDate_fromYear , month: fakeDatepickerDate_fromMonth , date: fakeDatepickerDate_fromDate ,hour:0,minute:0,second:0 }
      let fakeDatepickerDate_end = { year: fakeDatepickerDate_toYear , month: fakeDatepickerDate_toMonth, date: fakeDatepickerDate_toDate ,hour:23,minute:59,second:49 }
      
      var fake_fd =  moment().tz(zone_name).set(fakeDatepickerDate_start) ;
      var fake_td =  moment().tz(zone_name).set(fakeDatepickerDate_end) ;
      
      this.$store.commit('setDateFilter', ['fromDate',parseInt(fake_fd.format('x'))])
      this.$store.commit('setDateFilter', ['toDate',parseInt(fake_td.format('x'))])
      ////this.$store.commit('setDateFilter', ['sort','day'])
      if(th.unsub) {     
        th.unsub()

      } 
      this.cancel();
      let axiosSource = newAxios.CancelToken.source();
      this.request = { cancel: axiosSource.cancel };
      await this.fireAPIFilter(data, axiosSource.token )

     
    
    },

    async fireAPIFilter(data,cancelToken) {
      // TODO: fireAPIFilter method, search 
      let th = this;
      return new Promise((resolve, reject) => {    //Send token to your backend via HTTPS
         axios.get('reservations/filter', { params: data,  cancelToken: cancelToken  }).then(async response => {
            let mdData = []
            for (let j = 0; j < response.data.results.length; j++) {
              let dataToSave = response.data.results[j]
              dataToSave.offersBusy = true
              
              // dataToSave.offers = offers ;
              // let acceptedOffers = 0
              // for (let k = 0; k < dataToSave.offers.length; k++) {
              //   if(this.statuses[dataToSave.offers[k].status] == 'Accepted') {
              //     acceptedOffers++
              //   }

              //   if(this.statuses[dataToSave.offers[k].status] == 'Assigned') {
                  
              //     if(dataToSave.offers[k].timeline) {

              //       for (let n = 0; n < dataToSave.offers[k].timeline.length; n++) {
                      
              //         if(this.statuses[dataToSave.offers[k].timeline[n].status] == 'Assigned' && n === dataToSave.offers[k].timeline.length - 1 ) {
              //             dataToSave.assignedDriver = dataToSave.offers[k].timeline[n].userInCharge
              //         }
              //       }
              //     }
                     
              //   }
              // }

              // dataToSave.acceptedOffers = acceptedOffers
              mdData.push(dataToSave)
              
            }

            th.reservations.mdPage = data.page
            th.reservations.mdData = mdData
            th.reservations.mdCount = response.data.totalResults
            //th.reservations.mdCount = 50
            if(response.data.results.length > 0) {
              th.$store.commit('setDateFilter', ['first',response.data.results[0].id ])
              th.$store.commit('setDateFilter', ['last',response.data.results[response.data.results.length - 1].id ])
              th.$store.commit('setDateFilter', ['page',data.page])
            }
            for (let j = 0; j < response.data.results.length; j++) {
              let offers =  await this.getResOffers(response.data.results[j].id , j)
            }



            //th.triggeredByExternal = false
            //th.options = null
            resolve()
        }).catch(e => {
            console.log(e);
            reject()
        })
           
      }) 

    },
    cancel() {
        if (this.request) this.request.cancel('New request called');
    },
    async fireFilters() {
      let th = this;
      return new Promise((resolve, reject) => {
        var statusId = null ;
        if(th.$route.params.status){
          for (let i = 0; i < th.reservationStatuses.length; i++) {
            var aux = th.$route.params.status.charAt(0).toUpperCase() + th.$route.params.status.slice(1).replace("-"," ");
            if(th.reservationStatuses[i].name == aux){
                statusId = th.reservationStatuses[i].id
            }
          }

          th.$firebase.firestore().collection('users').doc(th.user.data.uid).collection('reservations').where("reservationStatus","==",statusId).orderBy("day", "asc").onSnapshot((querySnapshot) => {
            th.reservations = [];
            querySnapshot.forEach((doc) => {
              let dataToSave = doc.data();
              dataToSave.id = doc.id ;
              //dataToSave.miles = dataToSave.miles.split(" mi")[0]
              dataToSave.checked = false;
              //dataToSave.day = dataToSave.day.toString();
              let timePack = th.flowTimeZone(dataToSave.companyProfile.timeZone);
              

              let timeDiff = timePack.timezoneOffsetDif
              // Get UTC time to add or substract timezone difference
              let utcRealDateTime = moment.unix(dataToSave.day)

              if(timePack.viewerTimezonePosition == 'right') {
                  timeDiff = timeDiff * -1
              }

              let timeToShow = utcRealDateTime.utc().add(timeDiff, 'minutes').format('X') ;
              
              //th.selectedData.datetime = timeToShow
              dataToSave.day = timeToShow
              th.reservations.push(dataToSave);
              
              
            });
            resolve()
          });

        } else {
          let timezone ;
          for (let i = 0; i < this.companyProfiles.length; i++) {
            if(this.companyProfiles[i].id  ===  th.filters.companyProfile) {
              timezone = this.companyProfiles[i].timeZone
            }
            
          }
          var fd = moment().tz(timezone).set({hour:0,minute:0,second:0});
          var td = moment().tz(timezone).set({hour:23,minute:59,second:59});
         
          // import { collection, query, where } from "firebase/firestore";
          // const citiesRef = collection(db, "cities");

          // // Create a query against the collection.
          // const q = query(citiesRef, where("state", "==", "CA"));
          
          if(th.filters.companyProfile && this.filters.fromDate && this.filters.toDate) {
            //reservationsRef
          }

          const reservationsRef = th.$firebase.firestore().collection('users')
                                  .doc(th.user.data.uid)
                                  .collection('reservations')
                                  .where("day", ">=",parseInt(fd.format('X')) )
                            .where("day", "<=", parseInt(td.format('X')) )
  
                          .orderBy("day", "asc")
                          .onSnapshot((querySnapshot) => {
                              th.reservations.mdData = [];

                              querySnapshot.forEach((doc) => {
                                let dataToSave = doc.data();
                                dataToSave.id = doc.id ;
                                //dataToSave.miles = dataToSave.miles.split(" mi")[0]
                                dataToSave.checked = false;
                                //dataToSave.day = dataToSave.day.toString();
                                let timePack = th.flowTimeZone(dataToSave.companyProfile.timeZone);
                                

                                let timeDiff = timePack.timezoneOffsetDif
                                // Get UTC time to add or substract timezone difference
                                let utcRealDateTime = moment.unix(dataToSave.day)

                                if(timePack.viewerTimezonePosition == 'right') {
                                    timeDiff = timeDiff * -1
                                }

                                let timeToShow = utcRealDateTime.utc().add(timeDiff, 'minutes').format('X') ;
                                
                                //th.selectedData.datetime = timeToShow
                                dataToSave.day = timeToShow
                                
                                th.reservations.mdData.push(dataToSave);
                                
                                
                                
                              });
                              resolve()
            
                          });
         

          

        }
      })
    },
    async updatePagination(page, pageSize, sort, sortOrder){

      if(page > 1) {
          let direction = null
          page < this.filters.page ? direction = 'prev' : direction = 'next'

          this.$store.commit('setDateFilter', ['direction',direction])
      } else if( page == 0) {
        this.$store.commit('setDateFilter', ['direction',null])
      } else {
        this.$store.commit('setDateFilter', ['direction',null])
      }

     
      this.$store.commit('setDateFilter', ['page',page])
      this.$store.commit('setDateFilter', ['perPage',pageSize])
      this.rowsPerPage = pageSize
      //this.$store.commit('setDateFilter', ['sort',sort])
      
      if(this.triggeredByExternal) {
        this.triggeredByExternal = false;
      } else {
        this.isBusy = true;
        
        this.triggeredByExternal = true;
        await this.fireQuery()
        this.isBusy = false
      }
      
     
    },
    openFlightDialog(item){
      this.actualFlightPack = item.flightPack ;
      this.actualFlightPack.readableId = item.readableId
      this.actualFlightPack.jobType = item.jobType.jobType
      this.actualFlightPack.companyProfile = item.companyProfile
      this.flightDialog = true
      //this.getFlightInfo(item.flightPack, item.id)
    },
    getCompleteStatus(id) {
        for (let i = 0; i < this.paymentStatuses.length; i++) {
            if(this.paymentStatuses[i].id === id) {
                return this.paymentStatuses[i]
            }
            
        }
    },
    checkTimeToGo(date) {
      var startTime = moment();
      var endTime = moment.unix(date);
    
      //var hoursDiff = endTime.diff(startTime, 'hours');
    
      var minutesDiff = endTime.diff(startTime, 'minutes');
    
      //var secondsDiff = endTime.diff(startTime, 'seconds');

      var ms = endTime.diff(startTime);
      var d = moment.duration(ms);
      //var s = moment.utc(d.as('milliseconds')).format('HH:mm:ss')
      let className ;
      if(minutesDiff >= 180) {
        className = 'white-pu'
      } else if(minutesDiff >= 60 ) {
        className = 'yellow-pu'
      } else if(minutesDiff > 0) {
        className = 'red-pu'
      }

      
     
      let payload ;
      if(d >= 0 ) {
        payload = '<div class="time-to-go ' + className + '">' + d.days() + ' D, ' + d.hours() + ' H, ' + d.minutes() + ' M' + ' to PU' + '</div>';
        //return 'Days:' + d.days() + ' , Hours:' + d.hours() + ' , Minutes:' + d.minutes() + ' to pick up';
      } else {
        // Here goes logic with trip status , on location, dropped off etc
        className = 'green-pu'
        className = 'grey-pu'
        if(minutesDiff < 0 &&  minutesDiff >= -60) {
          payload = '<div class="time-to-go ' + className + '">' + 'Picked up' + '</div>';
          //payload = '<div class="' + className + '">' + d.days() + ' days, ' + d.hours() + ' h and ' + d.minutes() + ' min' + ' to PU' + '</div>';
        } else {
          payload = ''
        }
       
      }
      payload = '<div class="text-uppercase time-to-go ' + className + '">' + endTime.fromNow() + '</div>'
      return payload ;
      //return ''
       //moment.utc(duration.as('milliseconds')).format('HH:mm:ss')
    },
    async getLinkedUsers(requestedRole, requestedSubrole) {
      let th = this
      let ref 
     

      ref = firebase
              .firestore()
              .collection('users')
              .doc(this.user.data.uid).
              collection('linked-users')


      return new Promise((resolve, reject) => {
        let result = []
        //th.driverAssignment.linkedDrivers = []
        ref
        .where('role','==',requestedRole)
        .where('subrole','==',requestedSubrole)
        .get()
          .then(function(snapShot){
            snapShot.forEach((doc) => {
              let dataToSave = doc.data()
              dataToSave.id = doc.id
              result.push(dataToSave)
            });

           
            resolve(result)
          
          }).catch(function(error) {
             
              reject()
          });
        
            
      });
    },
    async getCommunities(requestedRole) {
      let th = this
      let ref 
      

      ref = firebase
              .firestore()
              .collection('users')
              .doc(this.user.data.uid).
              collection('communities')


      return new Promise((resolve, reject) => {
        th.driverAssignment.communities = []
        ref.where('role','==',requestedRole).get()
          .then(function(snapShot){
            snapShot.forEach((doc) => {
              let dataToSave = doc.data()
              dataToSave.id = doc.id
              th.driverAssignment.communities.push(dataToSave)
            });

           
            resolve()
          
          }).catch(function(error) {
             
              reject()
          });
        
            
      });
    },
    async getCompanyDrivers(){
        let th = this ;
        let data = {  
              ownerEmail: this.user.data.email,
              roleToGet: 'driver' ,
              ownerUid: this.user.data.uid
        }
        
        return new Promise((resolve, reject) => {
          
          axios.get('drivers', { params: data }).then(response =>{
            th.driverAssignment.companyDrivers = response.data.users ;
            th.directAssignment.companyDrivers = response.data.users ;
            //th.isBusy = false;
            resolve()
          }).catch(e => {
            console.log(e);
             // th.isBusy = false;
             reject()
          })
        })
          
    },
    async listenLiveChanges() {
      let th = this
      let ref 
      ref = firebase
              .firestore()
              .collection('users')
              .doc(this.user.data.uid)
              .collection('live-api-changes')
      return new Promise( (resolve, reject) => {
          th.unsubscribeLiveChanges = ref.onSnapshot(function(snapShot){
            snapShot.forEach(async (doc) => {
              let dataToSave = doc.data()
              dataToSave.id = doc.id

              let exists = await th.checkVisibleResLiveChanged(dataToSave.readableId)
              if(exists) {
                await th.refreshExternalChangedReservations(exists)
                await th.fireQuery()
                doc.ref.delete()
              }
            });
            resolve()
          
          })
        
            
      });
        
    },
    async checkVisibleResLiveChanged(readableId) {
      return new Promise((resolve, reject) => {
        let res = null
        for (let i = 0; i < this.reservations.mdData.length; i++) {
          if(this.reservations.mdData[i].readableId == readableId) {
            res =  { resId : this.reservations.mdData[i].id , index: i }
            
          }
          
        }
        resolve(res)
        
      })
      
    },
    async refreshExternalChangedReservations(data) {
      let th = this
      let ref 
      ref = firebase
              .firestore()
              .collection('users')
              .doc(this.user.data.uid)
              .collection('reservations')
              .doc(data.resId)
      return new Promise((resolve, reject) => {
        
        ref.get().then((doc) => {
          if(doc.exists) {
            let dataToSave = doc.data()
            dataToSave.id = doc.id
            th.reservations.mdData[data.index] = dataToSave
          }
          resolve()
        })
      })          
    },
    async obtainViewResources() {
      // TODO: Obtain view resources
      this.isBusy = true
      //await this.getStatuses()
      // await this.getDispatchers()

      if(this.user.data.activeRole === 'company-admin') {

        //await this.getCompanyProfiles()
        let ownerOperatorsToAdd = await this.getLinkedUsers('driver','owner-operator')

        // ownerOperatorsToAdd.forEach( driver => {
        //   if(driver.role == 'driver' && driver.tlc) {
        //         if(driver.tlc.number && driver.tlc.number != "") {
        //             let response = await this.getTLCStatus(driver.tlc.number)
        //             //driver.tlc.check = response.data
        //             if(response.data.length > 0) {
        //                 this.$nextTick(()=> {

        //                     driver.tlc.check = response.data[0]
        //                 })
        //             }
        //         }
        //   }
        //   if(driver.role == 'driver' ) {

        //       for (let n = 0; n < driver.fleet.length; n++) {
        //           console.log(driver.fleet[n])
        //           if(driver.fleet[n].mainInfo.plate && driver.fleet[n].mainInfo.plate != '') {
                      
        //               let response = await this.getDMVStatus(driver.fleet[n].mainInfo.plate)
        //               if(response.data.length > 0) {
        //                   this.$nextTick(()=> {

        //                       driver.fleet[n].dmvCheck = response.data[0]
        //                   })
        //               }
        //           }
                  
                  
        //       }
        //   } 
        // });


        this.driverAssignment.linkedAvailableUsersToOffer = [].concat(this.driverAssignment.linkedAvailableUsersToOffer, ownerOperatorsToAdd);
        this.directAssignment.linkedAvailableUsersToOffer = [].concat(this.directAssignment.linkedAvailableUsersToOffer, ownerOperatorsToAdd);
        this.directAssignment.companyDrivers = await this.getLinkedUsers('driver','company');

        let companyAdminsToAdd = await this.getLinkedUsers('company-admin',null)
        this.driverAssignment.linkedAvailableUsersToOffer = [].concat(this.driverAssignment.linkedAvailableUsersToOffer, companyAdminsToAdd);

        let providersToAdd = await this.getLinkedUsers('provider',null)
        this.driverAssignment.linkedAvailableUsersToOffer = [].concat(this.driverAssignment.linkedAvailableUsersToOffer, providersToAdd);
        await this.getCommunities('driver')
        //await this.getCompanyDrivers()
      } else if(this.user.data.activeRole === 'provider') {
        let ownerOperatorsToAdd = await this.getLinkedUsers('driver','owner-operator')

        // ownerOperatorsToAdd.forEach(async driver => {
        //   if(driver.role == 'driver' && driver.tlc) {
        //         if(driver.tlc.number && driver.tlc.number != "") {
        //             let response = await this.getTLCStatus(driver.tlc.number)
        //             //driver.tlc.check = response.data
        //             if(response.data.length > 0) {
        //                 this.$nextTick(()=> {

        //                     driver.tlc.check = response.data[0]
        //                 })
        //             }
        //         }
        //   }
        //   if(driver.role == 'driver' ) {

        //       for (let n = 0; n < driver.fleet.length; n++) {
        //           console.log(driver.fleet[n])
        //           if(driver.fleet[n].mainInfo.plate && driver.fleet[n].mainInfo.plate != '') {
                      
        //               let response = await this.getDMVStatus(driver.fleet[n].mainInfo.plate)
        //               if(response.data.length > 0) {
        //                   this.$nextTick(()=> {

        //                       driver.fleet[n].dmvCheck = response.data[0]
        //                   })
        //               }
        //           }
                  
                  
        //       }
        //   } 
        // });


        this.driverAssignment.linkedAvailableUsersToOffer = [].concat(this.driverAssignment.linkedAvailableUsersToOffer, ownerOperatorsToAdd);
        this.directAssignment.linkedAvailableUsersToOffer = [].concat(this.directAssignment.linkedAvailableUsersToOffer, ownerOperatorsToAdd);

        let companyAdminsToAdd = await this.getLinkedUsers('company-admin',null)
        this.driverAssignment.linkedAvailableUsersToOffer = [].concat(this.driverAssignment.linkedAvailableUsersToOffer, companyAdminsToAdd);

        let providersToAdd = await this.getLinkedUsers('provider',null)
        this.driverAssignment.linkedAvailableUsersToOffer = [].concat(this.driverAssignment.linkedAvailableUsersToOffer, providersToAdd);
      } else {

      }

      

       


      await this.getAddons()
      //await this.getVehicleTypes()
      await this.getBaseVehicleTypes()
      //await this.getJobTypes()
      //await this.filerByStatus()
      await this.listenLiveChanges()
      //await this.updatePagination(1, this.filters.perPage)
     
      this.triggeredByExternal = true
     
      await this.fireQuery()
      //await this.preset('today')
      //this.updatePagination(this.filters.page, this.filters.perPage)
      this.isBusy = false;
      
      this.dateLoaded = true
      

    },
    async updateReservation(reservation,index,col){
      // resStatus
      // tripStatus
      // extras
      // actualSaving: {
      //   index: null,
      //   col: null,
      //   busy: false
      // },
      this.actualSaving.busy = true;
      this.actualSaving.index = index;
      this.actualSaving.col = col;

      console.log('update reservation', reservation)
      let th = this;
      return new Promise((resolve, reject) => {
        firebase.firestore().collection('users').doc(this.user.data.uid).collection('reservations').doc(reservation.id).update(reservation)
          .then(function(){
              th.snackMessage = 'Reservation updated';
              th.actualSaving.busy = true;
              th.showSnackbar = true;
              th.actualSaving.busy = false;
              th.actualSaving.index = null;
              th.actualSaving.col = null;
              resolve()
          
          }).catch(function(error) {
              th.snackMessage = 'Ooopss something went wrong';
              th.actualSaving.busy = true;
              th.showSnackbar = true;
              th.actualSaving.busy = false;
              th.actualSaving.index = null;
              th.actualSaving.col = null;
              console.log("Error getting document:", error);
              reject()
          });
        
            
      });
 
    },
    async filerByStatus(){
      //await this.getStatuses()
      let th = this;
      return new Promise((resolve, reject) => {
        var statusId = null ;
        if(th.$route.params.status){
          for (let i = 0; i < th.reservationStatuses.length; i++) {
            var aux = th.$route.params.status.charAt(0).toUpperCase() + th.$route.params.status.slice(1).replace("-"," ");
            if(th.reservationStatuses[i].name == aux){
                statusId = th.reservationStatuses[i].id
            }
          }

          th.$firebase.firestore().collection('users').doc(th.user.data.uid).collection('reservations').where("reservationStatus","==",statusId).orderBy("day", "asc").onSnapshot((querySnapshot) => {
            th.reservations = [];
            querySnapshot.forEach((doc) => {
              let dataToSave = doc.data();
              dataToSave.id = doc.id ;
              //dataToSave.miles = dataToSave.miles.split(" mi")[0]
              dataToSave.checked = false;
              //dataToSave.day = dataToSave.day.toString();
              let timePack = th.flowTimeZone(dataToSave.companyProfile.timeZone);
              

              let timeDiff = timePack.timezoneOffsetDif
              // Get UTC time to add or substract timezone difference
              let utcRealDateTime = moment.unix(dataToSave.day)

              if(timePack.viewerTimezonePosition == 'right') {
                  timeDiff = timeDiff * -1
              }

              let timeToShow = utcRealDateTime.utc().add(timeDiff, 'minutes').format('X') ;
              
              //th.selectedData.datetime = timeToShow
              dataToSave.day = timeToShow
              th.reservations.push(dataToSave);
              
              
            });
            resolve()
          });

        } else {
          let timezone ;
          for (let i = 0; i < this.companyProfiles.length; i++) {
            if(this.companyProfiles[i].id  ===  th.filters.companyProfile) {
              timezone = this.companyProfiles[i].timeZone
            }
            
          }
          var fd = moment().tz(timezone).set({hour:0,minute:0,second:0});
          var td = moment().tz(timezone).set({hour:23,minute:59,second:59});
         
          this.filters.fromDate = parseInt(fd.format('x'))
          this.filters.toDate = parseInt(fd.format('x'))


          th.$firebase.firestore().collection('users')
                                  .doc(th.user.data.uid)
                                  .collection('reservations')
                                  .where("day", ">=",parseInt(fd.format('X')) )
                                  .where("day", "<=", parseInt(td.format('X')) )
                                  .orderBy("day", "asc")
                                  .onSnapshot((querySnapshot) => {
            th.reservations.mdData = [];

            querySnapshot.forEach((doc) => {
              let dataToSave = doc.data();
              dataToSave.id = doc.id ;
              //dataToSave.miles = dataToSave.miles.split(" mi")[0]
              dataToSave.checked = false;
              //dataToSave.day = dataToSave.day.toString();
              let timePack = th.flowTimeZone(dataToSave.companyProfile.timeZone);
              

              let timeDiff = timePack.timezoneOffsetDif
              // Get UTC time to add or substract timezone difference
              let utcRealDateTime = moment.unix(dataToSave.day)

              if(timePack.viewerTimezonePosition == 'right') {
                  timeDiff = timeDiff * -1
              }

              let timeToShow = utcRealDateTime.utc().add(timeDiff, 'minutes').format('X') ;
              
              //th.selectedData.datetime = timeToShow
              dataToSave.day = timeToShow
              
              th.reservations.mdData.push(dataToSave);
              
              
              
            });
            resolve()
            
          });

          th.reservations.mdCount = th.reservations.mdData.length
         

          

        }
      })
        
    
    },
    async getStatuses() {
        ///////
        let th = this;
        return new Promise((resolve, reject) => {
            th.statusesRef.orderBy('name').onSnapshot((querySnapshot) => {
                th.reservationStatuses =  []
                th.driverStatuses =  []
                th.paymentStatuses =  []
                th.tripStatuses =  []
                querySnapshot.forEach((doc) => {

                    let dataToSave = doc.data();
                    dataToSave.id = doc.id ;
                    switch(dataToSave.type) {
                        case 'reservation':
                            th.reservationStatuses.push(dataToSave);
                            break;
                        case 'driver':
                            th.driverStatuses.push(dataToSave);
                            break;
                        case 'payment':
                            th.paymentStatuses.push(dataToSave);
                            break;
                        case 'trip':
                            th.tripStatuses.push(dataToSave) ;
                            break; 
                        
                    }
                    
                    
                });
                resolve()
            })
            
        }) 

        
    },
    getJobTypeName(jobTypeId) {
        for (let i = 0; i < this.jobTypes.length; i++) {
            if(this.jobTypes[i].id == jobTypeId ) {
                return this.jobTypes[i].name ;
            }   
        }
    },
    async getJobTypes() {
        let th = this;
        return new Promise((resolve, reject) => {
            th.jobTypesRef.orderBy('order').onSnapshot((querySnapshot) => {
                th.jobTypes = [];
                querySnapshot.forEach((doc) => {

                    let dataToSave = doc.data();
                    dataToSave.id = doc.id ;
                    th.jobTypes.push(dataToSave);
                    
                });
                resolve()
            });
        })  
    },
    async getBaseVehicleTypes() {
        let th = this;
        return new Promise((resolve, reject) => {
            firebase.firestore().collection('vehicle-types').orderBy("order").onSnapshot((querySnapshot) => {
                th.baseVehicleTypes = [];
                querySnapshot.forEach((doc) => {

                let dataToSave = doc.data();
                dataToSave.id = doc.id ;
                th.baseVehicleTypes.push(dataToSave);
                

                });    
                
                resolve()
                
            });
        })  
    },
    async getVehicleTypes() {
        let th = this;
        return new Promise((resolve, reject) => {
            firebase.firestore().collection('users').doc(th.user.data.uid).collection('vehicle-types').orderBy("order").onSnapshot((querySnapshot) => {
                th.vehicleTypes = [];
                querySnapshot.forEach((doc) => {

                let dataToSave = doc.data();
                dataToSave.id = doc.id ;
                th.vehicleTypes.push(dataToSave);
                

                });    
                
                resolve()
                
            });
        })  
    },
    async getDispatchers(){
        let th = this ;

        let data = {  
                ownerEmail: this.$store.getters.user.data.email,
                roleToGet: 'dispatcher'
        }

        
        return new Promise((resolve, reject) => {
            th.$firebase.auth().currentUser.getIdToken(true).then(function(idToken) {
                //Send token to your backend via HTTPS
                //...
                axios.get('dispatchers', { params: data }).then(response =>{
                    th.dispatchers = response.data.users ;
                    resolve()
                }).catch(e => {
                    console.log(e);
                    reject()
                })
            }).catch(function(error) {
                //Handle error
            });
        }) 

    
    },
    async getAddons() {
        let th = this;
        return new Promise((resolve, reject) => {
            firebase.firestore().collection('users').doc(th.user.data.uid).collection('add-ons').onSnapshot((querySnapshot) => {
                th.addOns = [];
                querySnapshot.forEach((doc) => {
                    
                    let dataToSave = doc.data();
                    dataToSave.id = doc.id ;
                    th.addOns.push(dataToSave);
                    
                });
                resolve()
            });
        })  
    },
    async getCompanyProfiles() {
        let th = this;
        return new Promise((resolve, reject) => {
            firebase.firestore().collection('users').doc(th.user.data.uid).collection('company-profiles').onSnapshot((querySnapshot) => {
                th.companyProfiles = [];
                querySnapshot.forEach((doc) => {
                    
                    let dataToSave = doc.data();
                    dataToSave.id = doc.id ;
                    if(dataToSave.default) {
                      
                     
              

                      th.$store.commit('set', ['timezone', dataToSave.timeZone ])
                      th.$store.commit('set', ['utc',moment.tz(dataToSave.timeZone).utcOffset() ])
                      //th.$store.commit('set', ['time',moment.tz(dataToSave.timeZone).format('hh:mm A (HH:mm [HS])') ])
                      //th.$store.commit('set', ['date',moment.tz(dataToSave.timeZone).format('dddd, MMM Do YYYY') ])
                      //$store.commit('toggleSidebarMobile')
                      if(th.filters.companyProfile === null) {
                         th.filters.companyProfile = dataToSave.id
                      }
                     
                    }

                    th.companyProfiles.push(dataToSave);
                    
                });
                resolve()
            });
        })  
    },
    updateTime() {
        var cd = moment(new Date()).tz(this.reservation.companyProfile.timeZone);
        this.companyActualDateTime = cd.format('dddd DD MMMM YYYY hh:mm a') ;
        
    },
    flowTimeZone(timeZone) {
        let timePack = {
          timezoneOffsetDif: null,
          viewerTimezonePosition: null
        }

        var tz = moment.tz.guess();
        var dbaTZoffset = moment.tz(timeZone).utcOffset()

        var creatorTZoffset = moment.tz(tz).utcOffset()


        if(creatorTZoffset > dbaTZoffset) {
            // Ej USEastern (dba) -240  / BS AS (creator) -180 / 
            // Moscu 180 (dba) // nuevadelih (creator) 300
            timePack.timezoneOffsetDif = creatorTZoffset - dbaTZoffset ;
            timePack.viewerTimezonePosition = 'right';
            // -180 -  -240 = 60
            // 300 - 180 = 120
        } else if(dbaTZoffset > creatorTZoffset) {

            // Ej USEastern(creator) -240  / BS AS (dba) -180 / 
            // Moscu 180 (creator) // nuevadelih (dba) 300

            timePack.timezoneOffsetDif = dbaTZoffset - creatorTZoffset ;
            timePack.viewerTimezonePosition = 'left';
            // -240 - -180 = 
            // 180 - 300 = -120
        } else {
            timePack.timezoneOffsetDif = 0;
            timePack.viewerTimezonePosition = 'equal';
        }

        return timePack;




    },
    roundNumber(number) {
          number = number.toString(); //If it's not already a String
          if(number.indexOf(".") != -1) {
              number = number.slice(0, (number.indexOf("."))+3); //With 3 exposing the hundredths place
          } 
          
          return number
    },
    scrollLeft() {
      var table = this.$refs.masterTable.$el ;
      table.scrollLeft -= 300;
    },
    scrollRight() {
     
      var table = this.$refs.masterTable.$el ;
      //var content = table.querySelectorAll('.md-scrollbar')[0];
      table.scrollLeft += 300;
      //table.scrollTo(900,0);
      
      
    },
    setWidth() {
      // if (this.MdTable.fixedHeader) {
      //   var cellSelector = 'md-table-cell';
      //   var thEls = this.getChildNodesBySelector(this.$el.parentNode, 'md-table-head');
      //   var tdEls = this.MdTable.contentEl.querySelectorAll('tr:first-child .' + cellSelector);
      //   var nodeIndex = this.getNodeIndex(thEls, this.$el);

      //   this.width = tdEls[nodeIndex].offsetWidth;
      // }
    },
    async fireGlobalCheck() {
         let data = {
             test: true
         }
         axios.put('check-flight-status-globally/'

            ).then(response =>{
            }).catch(e => {
                // this.reservation.flightInfo = null;
                // this.loadingFlight = false ;
                console.log(e);
                // this.serverError = false ;
            })  

    },
    async getFlightInfo(flightPack, resId){
        let th = this;
        return new Promise((resolve, reject) => {
            let data = {
                flightNumber: flightPack.schedule.ident,
                departuretime: flightPack.schedule.departuretime,
                resId: resId,
                user: th.user.data.uid
            }

            axios.post('live-flight-info/' , data

            ).then(response =>{
                
                
                if(response.data.result) {
                  let flight = response.data.result.flights[0]
                   
                }

                // Si actualarrivaltime es 0 esta en el aire
                // estimated arrival time (hora estimada de aterrizaje)


                // Si actualdeparturetime es 0 no depego
                // estimated departure time (hora estimada de aterrizaje)


                // if(callback.FlightInfoExResult) {
                
                        
                // }
                resolve()
            }).catch(e => {
                // this.reservation.flightInfo = null;
                // this.loadingFlight = false ;
                console.log(e);
                reject()
                // this.serverError = false ;
            })  

            
          
        })  
    },
    myEventHandler(e) {
        // your code for handling resize...
        this.$store.commit('centerDialog',window.innerWidth) 
        this.updateTableHeight()
        if(this.dialogWidth >= 992) {
          this.mainToolbarContracted = true
        }
        ////
    },
    updateTableHeight(){
      // Master table content element
      let toolbarHeight = null
      if(this.$refs.masterToolbar) {

        toolbarHeight = this.$refs.masterToolbar.$el.offsetHeight ;
      }
      //let bottombarHeight = this.$refs.bottomBar.$el.offsetHeight ;
      if(this.$refs.masterTable) {
        let tableHeaderHeight = this.$refs.masterTable.$children[0].$children[0].$el.offsetHeight

        let tableHeight = this.$window.innerHeight - (56 + toolbarHeight + tableHeaderHeight /* + bottombarHeight */ + 10 + 57) 
        

        this.$refs.masterTable.$children[0].$children[1].$el.style.height = tableHeight + "px"
        this.$refs.masterTable.$children[0].$children[1].$el.style.maxHeight = tableHeight + "px"
        this.tableContentHeight = tableHeight
        //this.updatePaginatorPosition()

      } else {
        this.tableContentHeight = null
      }
      
      
    },
    paymentStatus(statusId) {
      for (let i = 0; i < this.paymentStatuses.length; i++) {
        if(this.paymentStatuses[i].id === statusId) {
          if(this.paymentStatuses[i].name === 'Partially paid') {
            return 'partially-paid'
          } else {
            return this.paymentStatuses[i].name.toLowerCase();
          }
          
        }
      }
    },
    isSameDay(dateA, dateB){
      let a = moment.unix(dateA).format("YYYY-MM-DD") ;
      let p = moment.unix(dateB).format("YYYY-MM-DD") ;
      //moment('2010-01-01').isSame('2010-02-01', 'day'); 
      return moment(a).isSame(p,'day')
      
    },
    isDiffDay(actualDate, previousDate){
      let a = moment.unix(actualDate).format("YYYY-MM-DD") ;
      let p = moment.unix(previousDate).format("YYYY-MM-DD") ;
      //moment('2010-01-01').isSame('2010-02-01', 'day'); 
      return !moment(a).isSame(p,'day')
      
    },
    resetModalInfo(){
      this.showDialog = false;
      this.modalInfo.daytime.day = null;
      this.modalInfo.daytime.time = null;
      this.modalInfo.resId = null;
      this.modalInfo.reservation = null ;
      this.modalInfo.passenger.email = null ;
      this.modalInfo.passenger.phone = null ;
      this.modalInfo.passenger.qty = null ;
      this.modalInfo.passenger.luggage = null ;


    },
    
    openModal(fieldType,item) {
      this.modalInfo.type = fieldType ;
      this.modalInfo.resId = item.readableId;
      switch(fieldType){
        case 'daytime':
          let formattedDate = moment.unix(item.day).format("YYYY-MM-DD HH:mm") ;
          this.modalInfo.daytime.day = item.day;
          //this.modalInfo.daytime.day = moment.unix(item.day).format("YYYY-MM-DD HH:mm");
          this.modalInfo.daytime.time = item.time;
          
          this.modalInfo.reservation = item;
        break;
        case 'passenger':
          this.modalInfo.passenger.firstName = item.passenger.firstName ;
          this.modalInfo.passenger.lastName = item.passenger.lastName ;
          this.modalInfo.passenger.email = item.passenger.email ;
          this.modalInfo.passenger.phone = item.passenger.phone ;
          this.modalInfo.passenger.qty = item.passenger.qty ;
          this.modalInfo.passenger.luggage = item.passenger.luggage ;
        
        break;
      }
      this.showDialog = true;
    },
    onSelect (items) {
    },
    shuffleArray (array) {
      for (let i = array.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1))
        let temp = array[i]
        array[i] = array[j]
        array[j] = temp
      }
      return array
    },
    getShuffledUsersData () {
      return this.shuffleArray(usersData.slice(0))
    },
    editOrView(item) {
      this.$router.push({path: `/app/reservations/${item.readableId}/${item.id}`})
    },
    rowClicked (item, index) {
      this.$router.push({path: `/app/reservations/${item.readableId}/${item.id}`})
    },
    pageChange (val) {
      this.$router.push({ query: { page: val }})
    },
    newReservation(){
      this.$router.push({path: `/app/reservations/new`})
    },
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page);
    },
    dataManager(sortOrder, pagination) {
      /*
      if (this.reservations.length < 1) return;

      let local = this.reservations;
      // sortOrder can be empty, so we have to check for that as well
      if (sortOrder.length > 0) {
        local = _.orderBy(
          local,
          sortOrder[0].sortField,
          sortOrder[0].direction
        );
      }

      pagination = this.$refs.vuetable.makePagination(
        local.length,
        this.perPage
      );
      let from = pagination.from - 1;
      let to = from + this.perPage;

      return {
        pagination: pagination,
        data: _.slice(local, from, to)
      };
      */
    },
    updateTableFixedColumnsData(){
      if(this.$refs.masterTable) {
        var table = this.$refs.masterTable.$el ;

        let firstColumns = [] 
        var content = table.querySelectorAll('table tbody tr')
        
        if(content.length > 0){
          for (let i = 0; i < content.length; i++) {
            let row = {};
            let last = content.length - 1
            row.height = last == i  ? content[i].offsetHeight - 56 : content[i].offsetHeight ;
            let dateRow = content[i].classList.contains('blue-back');
            let dateWidth = content[i].querySelectorAll('.time-info')
            // if(!dateRow) {
            //   row.height = row.height - 35
            // }
            row.columns = {
              first : dateRow ? null : content[i].children[1].offsetWidth,
              second: dateRow ? null : dateWidth[0].offsetWidth + 35,
              id: dateRow ? null : content[i].children[1].innerText,
              date: dateRow ? content[i].children[0].innerText : content[i].dataset.datetime,
              timezone: dateRow ? null : content[i].dataset.timezone,
              realid: dateRow ? null : content[i].dataset.realid,
            }
            firstColumns.push(row);
           
            
          }
        }
        this.tableFixedColumns.data = firstColumns
      } else {
         this.tableFixedColumns.data = []
      }
      // this.$nextTick(function(){
      //   window.document.querySelectorAll('.fixed-columns-content')[0].addEventListener('scroll', this.onFixedColumnVertical)
      // })
      

    },
    updatePaginatorPosition() {
        if(this.$refs.masterTable) {
          var table = this.$refs.masterTable.$el ;

          let tableContentWidth = this.$refs.masterTable.$children[0].$children[1].$el.offsetWidth
          let tableConteinerWidth = this.$refs.masterTable.$children[0].$el.offsetWidth
          let paginator = this.$refs.masterTable.$children[0].$children[1].$el.querySelectorAll('.md-table-pagination')[0]


          let paginationRight = (tableContentWidth - tableConteinerWidth - table.scrollLeft) + 'px'
          //paginator.style.right = paginationRight
          let viewHeight = window.innerHeight - 56

          let paginatorTop = (this.$refs.masterTable.$children[0].$children[1].$el.offsetHeight -  paginator.offsetHeight) + (this.$refs.masterTable.$children[0].$children[1].$el.scrollTop)
          if(paginatorTop < viewHeight) {
            this.$refs.masterTable.$children[0].$el.style.height =( viewHeight - 64 ) + 'px'
          } else {
          }
          //paginator.style.top = paginatorTop + 'px'
        }
    },
    onTableScrollHorizontal() {
        
      if(this.dialogWidth >= 992) {

        var table = this.$refs.masterTable.$el ;
        if(table.scrollLeft > 20 ) {
          this.fixedColShadowed = true;
          
        } else {
          this.fixedColShadowed = false;
          
        }

        if(table.scrollLeft > this.tableFixedColumns.data[0].columns.first ) {
        
          // var content = table.querySelectorAll('.md-table-content')[0].scrollTop  
        
          
          
          this.tableFixedColumns.show = true;
          this.$nextTick(function(){
            this.$refs.fixedColumnsContent.scrollTop = this.$refs.masterTable.$children[0].$children[1].$el.scrollTop
          })
        
        } else {
          this.tableFixedColumns.show = false;
        }


      }

       // this.updatePaginatorPosition()


    },
    onTableScrollVertical(ev) {
      if(this.$refs.fixedColumnsContent) {
        this.$refs.fixedColumnsContent.scrollTop = ev.target.scrollTop
        
      }
      //this.updatePaginatorPosition()
    },
    onFixedColumnVertical(ev) {
      
      //this.$refs.masterTable.$children[0].$children[1].$el.scrollTop = ev.target.scrollTop
     
    }
  },
  validations: { 
      
      driverAssignment: {

        actualRes: {
          driverAmount: {
            required
          }
        },
        vehicleType: {
          id: {
            required
          }
        }
      },
      directAssignment: {

        actualRes: {
          driverAmount: {
            required
          }
        },
       
      },
      coveredArea: {
          fencesList: {
            minLength: minLength(1),
            required,
          }
      }
      
  }
}
</script>

<style  lang="scss">
    @import "./../../assets/scss/variables";

    .md-table-content {
      position: relative;
      direction:rtl;
      //display: flex;
      //flex-direction: column;

      & > table {
        //flex-grow:2;
        //height: 760px;
        direction:ltr;
      }
    }

    .md-snackbar {
      z-index: 3000;
    }
    .md-table-pagination {
      direction:ltr;
      position:fixed;
      bottom:0;
      right:0;
      left:0;
      //position:absolute;
      //left:0;
      //flex-grow:1;
      //margin-right: -10px;
      border-top:0px;
      z-index: 110;
      //width: 100%;
      background-color: $primary-blue;
      font-size:13px;
      -webkit-transition-property: none;
      -moz-transition-property: none;
      -o-transition-property: none;
      transition-property: none;
      .md-table-pagination-previous {
        margin-left:10px;
      }

    
    }

    .md-table-pagination .md-field {
      margin: 0px 15px 0 15px!important;
      width: 48px!important;
    }

    .assigned, .accepted {
      background-color: $assigned-to!important;
    }

    .pre-assigned, .on-hold {
      background-color: $primary-color!important;
    }

    .unassigned {
      background-color: $unassigned!important;
    }
    .quoted {
        background-color: $quoted!important;
    }

    .offered {
      background-color: $offered!important;
    }

    .taken {
      background-color: $taken!important;
    }

    
    .confirmed {
        background-color: $confirmed!important;
    } 

    .unconfirmed {
        background-color: $unconfirmed!important;
    } 

    .late-concellation{
        background-color: $late-cancellation!important;
    } 

   .cancelled {
        background-color: $cancelled!important;
    }

    

    
</style>
